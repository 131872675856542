import styled from "styled-components";

export const Container = styled.section`
  min-height: 100vh;
`;

export const Content = styled.main`
  display: block;
  max-width: 1120px;
  min-height: 750px;
  margin: 0 auto;
  align-items: start;
  justify-items: center;
  font-family: "Roboto", sans-serif;
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1rem;
  color: var(--text-title);

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    text-align: center;
    padding-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    margin: 1rem 0;
  }

  ol {
    margin-left: 1rem;
  }

  ol > li > ol > li {
    padding-left: 1rem;
  }

  ul {
    margin-top: 1rem;
  }

  p.em-vigor {
    text-align: end;
  }

  @media (max-width: 720px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`;
