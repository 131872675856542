import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { maskCpfCnpj, maskDate, maskPhone, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { API_URL } from '../../../services/api';
import { Container } from './styles';
import { Validator } from '../../../utils/validator';

export function CreateNewUserModal({ isOpen, onRequestClose }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleCreateNewUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    // const birthdayDate = moment(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD');

    // const today = new Date();
    // const year = today.getFullYear();
    // const minYear = +year - 125;
    // const maxYear = +year - 18;

    // const birthdayYear = birthdayDate.substring(0, 4);

    // if (
    //   birthdayYear < minYear.toString() ||
    //   birthdayYear > maxYear.toString()
    // ) {
    //   toast.error('Data de nascimento inválida!');
    //   setLoading(false);
    //   return;
    // }

    const body = {
      name,
      cpfCnpj: unMask(cpfCnpj),
      phone: `+55${unMask(phone)}`,
      email,
    };

    if (birthday) {
      const birthdayDate = moment(birthday, 'DD/MM/YYYY')
      const [valid, validMessage] = Validator.birthday(birthdayDate, 'user')

      if (valid) {
        body.birthday = birthdayDate.format('YYYY-MM-DD')
      } else {
        toast.error(validMessage);
        setLoading(false);
        return;
      }
    }

    const res = await fetch(`${API_URL}/pdvs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setName('');
      setCpfCnpj('');
      setBirthday('');
      setPhone('');
      setEmail('');
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleCreateNewUser}>
        <h2>Criar Novo Usuário</h2>

        <input
          placeholder="Nome ou Razão Social "
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          required
        />

        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <MaskedInput
          mask={maskDate}
          placeholder="Data de nascimento"
          guide={false}
          type="text"
          value={birthday}
          onChange={(event) => setBirthday(event.target.value)}
        />

        <MaskedInput
          mask={maskPhone}
          placeholder="Telefone"
          guide={false}
          type="tel"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          required
        />

        <input
          placeholder="E-mail"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />

        {loading ? (
          <button type="submit" disabled>
            Criando usuário
          </button>
        ) : (
          <button type="submit">Criar usuário</button>
        )}
      </Container>
    </Modal>
  );
}
