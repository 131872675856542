import { useEffect, useState } from 'react'
import useWindowSize from '../../../hooks/useWindowSize';

export default function WhatsappButton() {
  const [urlWhatsapp, setUrlWhatsapp] = useState('')
  const windowSize = useWindowSize()

  useEffect(() => {
    setUrlWhatsapp(`https://${windowSize.width < 1024 ? 'api' : 'web'}.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP}`)
  }, [windowSize.width])

  return (
    <a 
      href={urlWhatsapp}
      target="_blank"
      rel="noreferrer"
      style={{ 
        position:'fixed', 
        right:'10px', 
        bottom:'10px', 
        filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.3))'
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" width="50" height="50" fill="none">
        <path d="M1.892 36.985l2.48-9.093a17.608 17.608 0 01-2.333-8.762c0-9.666 7.826-17.54 17.433-17.54 4.666 0 9.038 1.83 12.344 5.138a17.506 17.506 0 015.107 12.402c0 9.667-7.825 17.541-17.433 17.541-2.92 0-5.786-.74-8.34-2.144l-9.258 2.458z" fill="url(#paint0_linear_whatsapp_desktop_logo)"></path>
        <path d="M1.267 37.614l2.572-9.408a18.217 18.217 0 01-2.406-9.076C1.414 9.094 9.515.943 19.472.943c4.831 0 9.368 1.885 12.785 5.323 3.417 3.438 5.29 8.003 5.29 12.864 0 10.018-8.1 18.17-18.057 18.17a17.82 17.82 0 01-8.633-2.218l-9.59 2.532zM11.28 31.81l.551.333a14.955 14.955 0 007.642 2.107c8.266 0 15.008-6.784 15.008-15.101 0-4.03-1.561-7.837-4.39-10.684C27.26 5.62 23.476 4.03 19.471 4.03c-8.285 0-15.027 6.784-15.027 15.101 0 2.847.79 5.638 2.297 8.04l.349.574-1.525 5.582 5.713-1.516z" fill="url(#paint1_linear_whatsapp_desktop_logo)"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.971 11.515c-.33-.758-.698-.776-1.01-.776-.276-.019-.57-.019-.882-.019-.294 0-.79.111-1.212.573-.423.462-1.58 1.553-1.58 3.79 0 2.236 1.617 4.399 1.837 4.694.22.296 3.123 5.028 7.715 6.858 3.821 1.515 4.593 1.22 5.42 1.127.826-.092 2.663-1.09 3.049-2.163.367-1.053.367-1.977.257-2.162-.11-.185-.423-.296-.864-.536-.459-.222-2.663-1.33-3.086-1.479-.422-.148-.716-.222-1.01.222-.294.462-1.157 1.479-1.433 1.774-.257.296-.532.333-.973.111-.46-.221-1.91-.702-3.638-2.255-1.34-1.201-2.24-2.698-2.516-3.142-.257-.462-.037-.702.202-.924.202-.203.46-.536.68-.795.22-.259.293-.462.459-.758.147-.295.073-.573-.037-.794-.11-.204-.974-2.44-1.378-3.346z" fill="#fff"></path>
        <defs>
          <pattern id="pattern0_whatsapp_desktop_logo" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use></use>
          </pattern>
          <linearGradient id="paint0_linear_whatsapp_desktop_logo" x1="19.404" y1="36.983" x2="19.404" y2="1.583" gradientUnits="userSpaceOnUse">
            <stop stopColor="#20B038"></stop>
            <stop offset="1" stopColor="#60D66A"></stop>
          </linearGradient>
          <linearGradient id="paint1_linear_whatsapp_desktop_logo" x1="19.404" y1="37.618" x2="19.404" y2=".948" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F9F9F9"></stop>
            <stop offset="1" stopColor="#fff"></stop>
          </linearGradient>
        </defs>
      </svg>
    </a>
  )
}
