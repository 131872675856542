import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .dropdown {
    background: var(--shape);
    padding: 0;

    .button {
      padding: 0px 10px
    }

    .dropdown-trigger,
    .dropdown-menu,
    .dropdown-content {
      background: var(--shape);
      padding: 0;
    }

    .dropdown-menu {
      margin-top: 0;
      right: 0;
      min-width: 12rem;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      z-index: 20;
    }

    .dropdown-content {
      border-radius: 4px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px;
  border: none;
  background: transparent;
`;
