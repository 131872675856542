import searchImg from '../../assets/search-black.svg';
import { Container, IconButton } from './styles';

export function SearchCadsUser({ onOpenSearchCadsUserModal }) {
  return (
    <Container>
      <header>
        <h2>Consultar Saldo Usuário</h2>
        <IconButton onClick={onOpenSearchCadsUserModal}>
          <img src={searchImg} alt="Pesquisar" />
        </IconButton>
      </header>
    </Container>
  );
}
