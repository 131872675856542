import { useEffect, useState } from 'react'
import moment from 'moment'

export const useFetch = (url) => {
  const [response, setResponse] = useState('')
  const [error, setError] = useState('')
  const [load, setLoad] = useState('')
  const [time, setTime] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoad('loading')
      try {
        const res = await fetch(url, {
          method: 'GET',
          cache: 'no-store',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('token'),
          },
        })
        const data = await res.json()
        setResponse(data)
      } catch (err) {
        setError(err)
      }
      setLoad('')
    }
    fetchData()
  }, [url, time])

  const refresh = () => setTime(moment().format())

  return { response, error, load, refresh }
}
