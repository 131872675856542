import addImg from '../../assets/add.svg';
import { Container, IconButton } from './styles';

export function CreateNewUser({ onOpenCreateNewUserModal }) {
  return (
    <Container>
      <header>
        <h2>Criar Novo Usuário</h2>
        <IconButton onClick={onOpenCreateNewUserModal}>
          <img src={addImg} alt="Adicionar" />
        </IconButton>
      </header>
    </Container>
  );
}
