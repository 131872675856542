import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 1.5rem;
    font-weight: 600;

    a {
      color: var(--blue) !important;
      text-decoration: underline;
    }
  }
`;
