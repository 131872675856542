import { Button, Dropdown, Icon } from 'rbx';
import { Container } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserDeleteModal } from '../Modals/UserDeleteModal';
import { useState } from 'react';

export function User({ userData, onOpenUserModal }) {
  const [openUserDeleteModal, setOpenUserDeleteModal] = useState(false)

  const toggleUserDeleteModal = () => setOpenUserDeleteModal(!openUserDeleteModal)

  const profile = localStorage.getItem('profile')

  return (
    <Container>
      <UserDeleteModal
        isOpen={openUserDeleteModal}
        onRequestClose={toggleUserDeleteModal}
        userData={userData}
      />

      <header>
        <h2>{userData.shortName}</h2>
        <Dropdown align='right'>
          <Dropdown.Trigger>
            <Button size='small'>
              <Icon size="small">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Icon>
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Menu>
            <Dropdown.Content>
              <Dropdown.Item onClick={onOpenUserModal}>
                <FontAwesomeIcon icon={faEdit} className="mr-2" /> Editar meus dados
              </Dropdown.Item>
              {
                profile === 'user' && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={toggleUserDeleteModal}>
                      <FontAwesomeIcon icon={faTrash} className="mr-2" /> Excluir minha conta
                    </Dropdown.Item>
                  </>
                )
              }
            </Dropdown.Content>
          </Dropdown.Menu>
        </Dropdown>
      </header>
      <div className='is-flex p-0 is-justify-content-space-between' style={{ background:'var(--shape)' }}>
        <span>
          <p>Tipo de usuário</p>
          {userData.profile === 'user' && <strong>Aplicativo</strong>}
          {userData.profile === 'pdv' && <strong>Ponto de Venda</strong>}
        </span>
        <span>
          <p>CPF / CNPJ</p>
          <strong>{userData.cpf_cnpj}</strong>
        </span>
      </div>
    </Container>
  );
}
