import { useState } from 'react';
import { RenewParkingModal } from '../Modals/RenewParkingModal';
import Timer from 'react-compound-timer';
import CarImg from '../../assets/car.svg';
import AddImg from '../../assets/add.svg';
import { Container, IconButton } from './styles';
import { Button, Notification } from 'rbx';
import moment from 'moment';
import { CancelScheduleParkingModal } from '../Modals/CancelScheduleParkingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export function VehiclesParked({
  vehiclesParkedData: vehiclesParked,
  fetchVehiclesParked,
  validTimeForParking,
  vehiclesSchedule,
  refreshScheduleParking
}) {
  const [isRenewParkingModalOpen, setIsRenewParkingModalOpen] = useState(false);
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');
  const [isCancelScheduleModalOpen, setIsCancelScheduleModalOpen] = useState(false)

  // const { response: responseScheduleParking, refresh: refreshScheduleParking } =
  //   useFetch(`${API_URL}/parkings/schedule/active`)
  
  // const vehiclesSchedule = responseScheduleParking?.data || []

  function handleOpenRenewParkingModal() {
    setIsRenewParkingModalOpen(true);
  }

  function handleCloseRenewParkingModal() {
    setIsRenewParkingModalOpen(false);
    fetchVehiclesParked();
    setTimeout(window.location.reload(), 3000);
  }

  const toggleCancelScheduleModal = () => {
    if(isCancelScheduleModalOpen) refreshScheduleParking()
    setIsCancelScheduleModalOpen(!isCancelScheduleModalOpen)
  }

  return (
    <Container>
      <RenewParkingModal
        isOpen={isRenewParkingModalOpen}
        onRequestClose={handleCloseRenewParkingModal}
        vehicleLicensePlate={vehicleLicensePlate}
        vehicleParkedData={ vehiclesParked?.find( veh => veh.licensePlate === vehicleLicensePlate ) }
      />

      <CancelScheduleParkingModal
        isOpen={isCancelScheduleModalOpen}
        onRequestClose={toggleCancelScheduleModal}
        vehicleSchedule={vehiclesSchedule?.find(vs => vs.licensePlate === vehicleLicensePlate)}
      />

      <header>
        <h2>Veículos estacionados</h2>
      </header>

      {
        validTimeForParking === false && (
          <Notification color='dark' textAlign='centered'>
            O estacionamento neste horário é livre.<br/>Você pode AGENDAR seu estacionamento 
            para o próximo dia e horário de funcionamento do Zona Azul.
          </Notification>
        )
      }

      {vehiclesParked !== null ? (
        vehiclesParked?.map((vehicle) => (
          <div key={vehicle.licensePlate}>
            <img src={CarImg} alt="Carro" />
            <span>
              <strong>{vehicle.licensePlate}</strong>
              <strong className="timer">
                <Timer
                  initialTime={vehicle.timeReaming}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => window.location.reload(),
                    },
                  ]}
                >
                  {() =>
                    vehicle.timeReaming !== 0 && (
                      <>
                        <Timer.Hours />h:<Timer.Minutes />m:<Timer.Seconds />s
                      </>
                    )
                  }
                </Timer>
              </strong>
            </span>
            <IconButton
              onClick={() => {
                handleOpenRenewParkingModal();
                setVehicleLicensePlate(vehicle.licensePlate);
              }}
            >
              <img src={AddImg} alt="Renovar estacionamento" />
            </IconButton>
          </div>
        ))
      ) : (
        <p>Nenhum veículo estacionado.</p>
      )}

      {vehiclesSchedule !== null ? (
        vehiclesSchedule?.map((vehicle) => (
          <div key={vehicle.licensePlate}>
            <img src={CarImg} alt="Carro" />
            <span>
              <strong>{vehicle.licensePlate}</strong>
              <Button
                size='small'
                fullwidth
                style={{ background:'var(--green)', color:'var(--shape)', borderRadius:'0.25rem', fontSize:'0.9rem', fontWeight:'500' }}
                title={ 'Inicio: '+moment(vehicle.startDate).format('DD/MM HH:mm')}
                onClick={() => {
                  toggleCancelScheduleModal()
                  setVehicleLicensePlate(vehicle.licensePlate)
                }}
              >
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                {moment(vehicle.startDate).format('DD/MM HH:mm')}
              </Button>
            </span>
            <IconButton
              onClick={() => {
                toggleCancelScheduleModal();
                setVehicleLicensePlate(vehicle.licensePlate);
              }}
            >
              <img src={AddImg} alt="Cancelar Agendamento" />
            </IconButton>
          </div>
        ))
      ) : (
        <p>Nenhum veículo estacionado.</p>
      )}
    </Container>
  );
}
