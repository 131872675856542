import bg from '../assets/bg-photo.png';
import styled from 'styled-components';

export const Container = styled.section`
  min-height: 100vh;
  background: url(${bg}) no-repeat center;
  background-size: cover;
`;

export const Cover = styled.div`
  max-width: 50vw;

  @media (max-width: 720px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: center;
  }

  @media (max-width: 321px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
  max-width: 1120px;
  min-height: 750px;
  margin: 0 auto;
  align-items: start;
  justify-items: center;
  padding: 1rem 1rem;
  color: #fff;

  h1 {
    font-size: 4.5rem;
  }

  p {
    font-size: 2.5rem;
    margin-top: 1rem;
  }

  span a {
    color: var(--text-title);
  }

  @media (max-width: 1080px) {
    gap: 2rem;
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    gap: 2rem;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
`;
