import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { API_URL } from '../../../services/api';
import { Container } from './styles';

export function RenewParkingModal({ isOpen, onRequestClose, vehicleLicensePlate: licensePlate, vehicleParkedData: vehicleParked }) {
  const [loadingRenew, setLoadingRenew] = useState('');
  const [loadingFinish, setLoadingFinish] = useState('');

  const handleRenewParking = async (e) => {
    e.preventDefault();
    if (!window.confirm('Confirma estender o estacionamento ?')) return

    setLoadingRenew(true);

    const imei = process.env.REACT_APP_IMEI_DEFAULT;

    const res = await fetch(`${API_URL}/parkings`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify({ licensePlate, imei, firebaseToken: '' }),
    });

    const response = await res.json();
    setLoadingRenew(false);
    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(onRequestClose, 3000);
    }
  };

  const handleFinishParking = async () => {
    if (!window.confirm('Confirma o encerramento ?')) return

    setLoadingFinish(true);

    const res = await fetch(`${API_URL}/finish-parkings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify({ licensePlate }),
    });

    const response = await res.json();
    setLoadingFinish(false);
    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(onRequestClose, 3000);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleRenewParking}>
        <h2>Veículo Estacionado: {licensePlate} </h2>

        <h3>Regra: {vehicleParked?.ruleName}</h3>

        {
          vehicleParked?.renewable
            ? vehicleParked?.maxCadReached && <h3>Você já atingiu o máximo de renovação.</h3>
            : <h3>Este estacionamento não pode ser renovado.</h3>
        }

        {
          vehicleParked?.renewable
            ? vehicleParked?.maxCadReached
                ? <p>Aqui você pode Encerrar seu estacionamento.</p>
                : <p>Aqui você pode Estender a permanência do seu estacionamento ou Encerrá-lo.</p>
            : <p>Aqui você pode Encerrar seu estacionamento.</p>
        }

        {
          vehicleParked?.renewable && (
            vehicleParked?.maxCadReached ? (
              <></>
            ) : (
              loadingRenew ? (
                <button type="submit" disabled> ESTENDENDO ... </button>
              ) : (
                <button type="submit"> ESTENDER </button>
              )
            )
          )
        }

        {loadingFinish ? (
          <button type='button' className="secondButton" disabled>
            ENCERRANDO ...
          </button>
        ) : (
          <button type='button' className="secondButton" onClick={handleFinishParking}>
            ENCERRAR
          </button>
        )}
      </Container>
    </Modal>
  );
}
