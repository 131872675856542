import { Header } from '../../components/Header';
import { Dashboard } from '../../components/Dashboard';
import { Container } from './styles';
import WhatsappButton from "../HomePage/components/WhatsappButton";

export default function DashboardPage() {
  return (
    <>
      <Header />
      <Container>
        <Dashboard />
      </Container>
      <WhatsappButton />
    </>
  );
}
