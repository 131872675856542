import { useContext } from "react";
import { Link } from "react-router-dom";
import logoImg from "../../assets/prancheta1_13.png";
import logoutImg from "../../assets/logout.svg";
import AuthContext from "../../contexts/AuthContext";
import { ScrollButton } from "../ScrollButton";
import { Container, Content } from "./styles";
import useMedia from "../../hooks/useMedia";

export function Header() {
  const { logout } = useContext(AuthContext);
  const mobile = useMedia("(max-width: 810px)");

  return (
    <>
      <Container>
        <Content>
          <Link to="/dashboard">
            <img src={logoImg} alt="Transitar Consultoria" />
          </Link>

          <button type="button" onClick={() => logout()}>
            Sair
            <img src={logoutImg} alt="Sair" />
          </button>
        </Content>
      </Container>
      {!mobile && <ScrollButton />}
    </>
  );
}
