import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Button } from "./styles";

export const ScrollButton = () => {
  const [visible, setVisible] = useState('none')

  const toogleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    setVisible(scrolled > 300 ? 'inline' : 'none')
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  window.addEventListener('scroll', toogleVisible)

  return (
    <Button onClick={ scrollToTop } style={{ display: visible }} >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  )
}