import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { maskCpfCnpj, maskPhone, maskDate, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { Container } from './styles';
import { API_URL } from '../../../services/api';
import { RedirectTo } from '../../../utils/redirectTo';
import { PasswordInput } from '../../PasswordInput';

export function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('')
  const [password, setPassword] = useState('');

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== emailConfirmation) {
      toast.error('Os emails informados não coincidem.'); return;
    }
    
    if (password.length < 6) {
      toast.error('A senha precisa ter no mínimo 6 caracteres.'); return;
    }

    setLoading(true);

    const birthDay = moment(birthday, 'DD/MM/YYYY');
    const birthdayDate = birthDay.format('YYYY-MM-DD');

    const today = new Date();
    const yearToday = today.getFullYear();
    const minYear =+ yearToday - 125;
    const maxYear =+ yearToday - 18;

    const birthdayYear = birthdayDate.substring(0, 4);

/*
    if (
      birthdayYear < minYear.toString() ||
      birthdayYear > maxYear.toString()
    ) {
      toast.error('Data de nascimento inválida!');
      setLoading(false);
      return;
    }
*/

    if (birthdayYear > yearToday) {
      toast.error('A data de nascimento não pode estar no futuro!');
      setLoading(false);
      return;      
    }
    if (birthdayYear < minYear.toString()) {
      toast.error('O usuário deve ter menos de 125 anos!');
      setLoading(false);
      return;
    } else if (birthdayYear > maxYear.toString()) {
      toast.error('O usuário deve ser maior de 18 anos!');
      setLoading(false);
      return;
    }

    const body = {
      name,
      cpfCnpj: unMask(cpfCnpj),
      birthday: birthdayDate,
      phone: `+55${unMask(phone)}`,
      email,
      password,
      profile: 'user',
    };

    const res = await fetch(`${API_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setName('');
      setCpfCnpj('');
      setBirthday('');
      setPhone('');
      setEmail('');
      setPassword('');
      setTimeout(() => history.push(RedirectTo.activate), 5000);
    }

    setLoading(false);
  };

  return (
    <Container>
      <ToastContainer />

      <h2 className='mb-2'>Crie sua conta</h2>
      <form onSubmit={handleSubmit}>
        <input
          placeholder="Nome ou Razão Social "
          type="text"
          maxLength="60"
          value={name}
          autoFocus
          onChange={(event) => setName(event.target.value)}
          required
        />

        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <MaskedInput
          mask={maskDate}
          placeholder="Data de nascimento"
          guide={false}
          type="text"
          value={birthday}
          onChange={(event) => setBirthday(event.target.value)}
          required
        />

        <MaskedInput
          mask={maskPhone}
          placeholder="Telefone"
          guide={false}
          type="tel"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          required
        />

        <input
          placeholder="E-mail"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <input
          placeholder="Repita o E-mail"
          type="email"
          value={emailConfirmation}
          onChange={(event) => setEmailConfirmation(event.target.value)}
          required
        />

        <PasswordInput
          placeholder="Senha"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          minLength={6}
          required
        />

        {loading ? (
          <button disabled type="submit">
            Criar conta
          </button>
        ) : (
          <button type="submit">Criar conta</button>
        )}

        <Link to={RedirectTo.login}>
          <button className="secondButton">Voltar ao login</button>
        </Link>
      </form>
    </Container>
  );
}
