import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import { API_URL } from '../../../services/api';
import { Container } from './styles';
import { RedirectTo } from '../../../utils/redirectTo';

export function ResendCode() {
  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const body = { cpfCnpj: unMask(cpfCnpj) };

    const res = await fetch(`${API_URL}/resend-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setCpfCnpj('');
      setTimeout(() => history.push(RedirectTo.activate), 5000);
    }

    setLoading(false);
  };

  return (
    <Container>
      <ToastContainer />

      <h2>Reenviar código de ativação</h2>

      <form onSubmit={handleSubmit}>
        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          autoFocus
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        {loading ? (
          <button disabled type="submit">
            Reenviar
          </button>
        ) : (
          <button type="submit">Reenviar</button>
        )}

        <Link to={RedirectTo.login}>
          <button className="secondButton">Voltar ao login</button>
        </Link>
      </form>
    </Container>
  );
}
