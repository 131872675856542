import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from 'qrcode';
import closeImg from '../../../assets/close.svg';
import addBlueImg from '../../../assets/add-blue.svg';
import removeBlueImg from '../../../assets/remove-blue.svg';
import successImg from '../../../assets/success.svg';
import pixImg from '../../../assets/pix.svg';
import pixWhiteImg from '../../../assets/pix-white.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import barcodeImg from '../../../assets/barcode.svg';
import barcodeWhiteImg from '../../../assets/barcode-white.svg';
import trafficCopImg from '../../../assets/traffic-cop-icon-19.png'
import {
  maskCardNumber,
  maskCardExpirationDate,
  maskCardCvv,
  unMask,
  maskQuantityCads,
} from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import Loading from '../../../helpers/Loading';
import {
  Container,
  Result,
  RadioContainer,
  RadioContainerPayment,
  RadioBox,
  InputsGrid,
  ValueContainer,
  IconButton,
  IconButtonContainer,
} from './styles';

export function CadsModal({ isOpen, onRequestClose, options }) {
  const [quantity, setQuantity] = useState('1');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [pixKey, setPixKey] = useState('');
  const [pixQR, setPixQR] = useState('');
  const [boletoUrl, setBoletoUrl] = useState('');
  const [boletoBarcode, setBoletoBarcode] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationDate, setCardExpirationDate] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [minValue, setMinValue] = useState(1);

  const profile = localStorage.getItem('profile');
  const discount = localStorage.getItem('discount');

  const handleQuantity = (operation) => {
    if (operation === 'add' && quantity < 999) {
      setQuantity(+quantity + 1);
    }
    if (operation === 'remove' && quantity > minValue) {
      setQuantity(+quantity - 1);
    }
  };

  const handleValidCard = (cardExpirationDate) => {
    var data = new Date();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear().toString().substr(2, 2);

    var month = cardExpirationDate.substr(0, 2);
    var year = cardExpirationDate.substr(2, 2);

    if (year < ano) {
      toast.error('Cartão com validade expirada!');
      return false;
    }

    if (year === ano && month < mes) {
      toast.error('Cartão com validade expirada!');
      return false;
    }
  };

  const handleBoleto = async (boletoId) => {
    const res = await fetch(`${API_URL}/transactions/${boletoId}`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });

    const response = await res.json();
    setBoletoUrl(response?.data?.boletoUrl);
    setBoletoBarcode(response?.data?.boletoBarcode);
  };

  const handleBuyCads = async (e) => {
    e.preventDefault();

    if (paymentMethod === '') {
      toast.error('Selecione uma forma de pagamento.');
      return;
    }
    if (+quantity < minValue) {
      if (paymentMethod === 'boleto') {
        toast.error(`Para Boleto a quantidade mínima é de ${minValue} Cartões.`)
      } else {
        toast.error(`A quantidade mínima é de ${minValue} Cartões.`)
      }
      return;
    }
    setLoading(true);

    const body = {
      quantity: +quantity,
      paymentMethod,
    }

    if (['credit_card','debit_card'].includes(paymentMethod)) {
      if (handleValidCard(unMask(cardExpirationDate)) === false) {
        return;
      }
      body.cardNumber = unMask(cardNumber)
      body.cardHolderName = cardHolderName
      body.cardExpirationDate = unMask(cardExpirationDate)
      body.cardCvv = cardCvv
    }

    const res = await fetch(`${API_URL}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();
    let boletoId = response?.data?.boletoId;
    setPixKey(response?.data?.pixQrCode);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);

      if (paymentMethod === 'boleto') {
        setTimeout(() => handleBoleto(boletoId), 2000);
      }

      if (paymentMethod === 'pix') {
        QRCode.toDataURL(response?.data?.pixQrCode).then((data) => {
          setPixQR(data);
        });
      }
    }
    setLoading(false);
  };

  const cleanState = () => {
    setQuantity('1')
    setPaymentMethod('')
    setPixKey('')
    setPixQR('')
    setBoletoUrl('')
    setBoletoBarcode('')
    setCardNumber('')
    setCardExpirationDate('')
    setCardHolderName('')
    setCardCvv('')
    setRequestSuccess('')
    setMinValue(1)

    onRequestClose()
  }

  const RadioBoxCad = ({ cads }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setQuantity(cads)}
        isActive={quantity === cads}
        activeColor="green"
      >
        <span>
          <span>{cads}</span> Cartões
        </span>
      </RadioBox>
    )
  }

  const RadioContainerBox = ({ values=[] }) => {
    return (
      <RadioContainer>
        {values.map(cads => (
          <RadioBoxCad key={cads} cads={cads} />
        ))}
      </RadioContainer>
    )
  }

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => {
          setPaymentMethod(method)
          setMinValue(method !== 'boleto' ? 1 : profile === 'user' ? 100 : 50)
        }}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  if (options?.educationalPeriod?.valid && profile !== 'pdv') {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={cleanState}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={cleanState}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        <Result className="animeLeft">
          <h2>Periodo Educativo</h2>
          <p className="animeLeft resultCard">
            {options?.educationalPeriod?.message}
          </p>
          <img src={trafficCopImg} alt="Sucesso" onClick={cleanState} />
        </Result>
      </Modal>
    );
  }

  if (requestSuccess !== '') {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={cleanState}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={cleanState}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        {paymentMethod === 'pix' && (
          <Result className="animeLeft">
            <h2>
              Utilize esse código QR ou a chave pix copia e cola para realizar o
              pagamento:
            </h2>
            {/* <p className="animeLeft">{pixKey}</p> */}
            {pixQR && <img src={pixQR} alt="Pix Código QR" />}
            <div className="tooltip">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(pixKey);
                  var tooltip = document.getElementById('myTooltip');
                  tooltip.innerHTML = 'Copiado!';
                }}
              >
                <span className="tooltiptext" id="myTooltip">
                  Copiar
                </span>
                Copiar chave pix
                <img src={pixWhiteImg} alt="Pix" />
              </button>
            </div>
          </Result>
        )}

        {(['credit_card','debit_card'].includes(paymentMethod)) && (
          <Result className="animeLeft">
            <h2>{requestSuccess}</h2>
            <p className="animeLeft resultCard">
              Estamos processando sua transação. Seus cartões serão creditados
              em alguns segundos.
            </p>
            <img src={successImg} alt="Sucesso" onClick={cleanState} />
          </Result>
        )}

        {paymentMethod === 'boleto' && (
          <Result className="animeLeft">
            <h2>
              Visualize o boleto abaixo ou copie o código de barras para
              realizar o pagamento:
            </h2>
            <p className="animeLeft">
              {boletoUrl ? (
                <a
                  href={boletoUrl}
                  target="_blank"
                  alt="Visualizar boleto"
                  rel="noopener noreferrer"
                >
                  {boletoUrl}
                </a>
              ) : (
                <Loading />
              )}
            </p>
            <div className="tooltip">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(boletoBarcode);
                  var tooltip = document.getElementById('myTooltip');
                  tooltip.innerHTML = 'Copiado!';
                }}
              >
                <span className="tooltiptext" id="myTooltip">
                  Copiar
                </span>
                Copiar código boleto
                <img src={barcodeWhiteImg} alt="Boleto" />
              </button>
            </div>
          </Result>
        )}
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={cleanState}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={cleanState}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleBuyCads}>
        <h2>Comprar Cartões</h2>

        {paymentMethod === 'boleto' ? (
          profile === 'user' ? (
            <RadioContainerBox values={[100, 125, 150]} />
          ) : (
            <RadioContainerBox values={[50, 75, 100]} />
          )
        ) : (
          <RadioContainerBox values={[10, 20, 30]} />
        )}

        <MaskedInput
          mask={maskQuantityCads}
          placeholder="Outra quantidade"
          guide={false}
          type="text"
          value={quantity}
          onChange={(event) => setQuantity(event.target.value)}
          required
        />
        <IconButtonContainer>
          <IconButton type="button" onClick={() => handleQuantity('remove')}>
            <img src={removeBlueImg} alt="Menos" />
          </IconButton>

          <IconButton type="button" onClick={() => handleQuantity('add')}>
            <img src={addBlueImg} alt="Mais" />
          </IconButton>
        </IconButtonContainer>

        <ValueContainer>
          {profile === 'pdv' ? (
            <>
              <h3>
                Desconto:{' '}
                <span style={{ color: '#33CC95' }}>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(quantity * 2 * (discount / 100))}
                </span>
              </h3>
              <h3>
                Valor total:{' '}
                <span>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(+quantity * 2 - quantity * 2 * (discount / 100))}
                </span>
              </h3>
            </>
          ) : (
            <h3>
              Valor total: <span>R$ {+quantity * 2},00</span>
            </h3>
          )}
        </ValueContainer>

        <RadioContainerPayment profile={profile}>
          <RadioBoxPayment label='Pix' method='pix' image={pixImg} />
          {/* <RadioBoxPayment label='Débito' method='debit_card' image={creditCardImg} /> */}
          <RadioBoxPayment label='Crédito' method='credit_card' image={creditCardImg} />
          <RadioBoxPayment label='Boleto' method='boleto' image={barcodeImg} />
        </RadioContainerPayment>

        {(['credit_card','debit_card'].includes(paymentMethod)) && (
          <InputsGrid className="animeLeft">
            <MaskedInput
              mask={maskCardNumber}
              placeholder="Número do cartão"
              guide={false}
              type="text"
              value={cardNumber}
              onChange={(event) => setCardNumber(event.target.value)}
              required
            />
            <input
              placeholder="Nome no cartão"
              type="text"
              value={cardHolderName}
              onChange={(event) => setCardHolderName(event.target.value)}
              required
            />
            <MaskedInput
              mask={maskCardExpirationDate}
              placeholder="Data de venc. (00/00)"
              guide={false}
              type="text"
              value={cardExpirationDate}
              onChange={(event) => setCardExpirationDate(event.target.value)}
              required
            />
            <MaskedInput
              mask={maskCardCvv}
              placeholder="CVV"
              guide={false}
              type="text"
              value={cardCvv}
              onChange={(event) => setCardCvv(event.target.value)}
              required
            />
          </InputsGrid>
        )}

        {loading ? (
          <button type="submit" disabled>
            Comprando
          </button>
        ) : (
          <button type="submit">Comprar</button>
        )}
      </Container>
    </Modal>
  );
}
