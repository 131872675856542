import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
// import successImg from '../../../assets/success.svg';
import { maskPlate } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import { Container, InputGrid } from './styles';

export function VehicleModal({ isOpen, onRequestClose, vehicle }) {
  const [licensePlate, setLicensePlate] = useState('');
  const [alias, setAlias] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState('');
  // const [requestSuccess, setRequestSuccess] = useState('');

  useEffect(() => {
    if (vehicle?.licensePlate) {
      setLicensePlate(vehicle?.licensePlate||'')
      setAlias(vehicle?.alias||'')
      setType(vehicle?.type||'')
    } else {
      setLicensePlate('');
      setAlias('');
      setType('');
    }
  },[vehicle])

  const handleNewVehicle = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      licensePlate: licensePlate.replace('-', ''),
      alias,
      type: type.toLowerCase(),
    };

    const res = await fetch(`${API_URL}/vehicles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    // console.log(body);
    // console.log(response);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setLicensePlate('');
      setAlias('');
      setType('');
    }

    setLoading(false);
  };

  // if (requestSuccess !== '')
  //   return (
  //     <Modal
  //       isOpen={isOpen}
  //       onRequestClose={onRequestClose}
  //       overlayClassName="react-modal-overlay"
  //       className="react-modal-content"
  //     >
  //       <button
  //         type="button"
  //         onClick={onRequestClose}
  //         className="react-modal-close"
  //       >
  //         <img src={closeImg} alt="Fechar modal" />
  //       </button>

  //       <Result className="animeLeft">
  //         <h2>{requestSuccess}</h2>
  //         <img src={successImg} alt="Sucesso" onClick={onRequestClose} />
  //       </Result>
  //     </Modal>
  //   );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleNewVehicle}>
        <h2>Cadastrar Veículo</h2>

        <InputGrid>
          <MaskedInput
            mask={maskPlate}
            placeholder="Placa"
            guide={false}
            type="text"
            value={licensePlate.toUpperCase()}
            onChange={(event) => setLicensePlate(event.target.value)}
            required
          />

          <input
            placeholder="Apelido deste veículo"
            type="text"
            maxLength={15}
            value={alias}
            onChange={(event) => setAlias(event.target.value.toUpperCase())}
          />
        </InputGrid>

        <select
          type="text"
          value={type}
          onChange={(event) => setType(event.target.value)}
          required
        >
          <option value="" disabled defaultValue hidden>
            Tipo de veículo
          </option>
          <option value="Carro">Carro</option>
          <option value="Caminhão">Caminhão</option>
          <option value="Motocicleta">Motocicleta</option>
          <option value="Ônibus">Ônibus</option>
        </select>

        {loading ? (
          <button type="submit" disabled>
            Salvando
          </button>
        ) : (
          <button type="submit">Salvar</button>
        )}
      </Container>
    </Modal>
  );
}
