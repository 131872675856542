import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { maskCpfCnpj, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import successImg from '../../../assets/success.svg';
import closeImg from '../../../assets/close.svg';
import { Container, Result } from './styles';

export function SearchCadsUserModal({ isOpen, onRequestClose }) {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [cads, setCads] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearchCadsUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      cpfCnpj: unMask(cpfCnpj),
    };

    const res = await fetch(`${API_URL}/pdvs/user-balance-inquiry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setCads(response?.data?.userCads);
      setRequestSuccess(response.message);
    }
    setLoading(false);
  };

  const closeModal = () => {
    setCpfCnpj('')
    setRequestSuccess('')
    onRequestClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={closeModal}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      {
        requestSuccess !== '' ? (

          <Result className="animeLeft">
            <h2>Consulta realizada</h2>
            <p>
              O usuário <span>{cpfCnpj}</span> possui <span>{cads}</span> cartões.
            </p>
            <img 
              src={successImg} 
              alt="Sucesso" 
              onClick={closeModal} />
          </Result>

        ) : (

          <Container onSubmit={handleSearchCadsUser}>
            <h2>Consultar Saldo Usuário</h2>

            <MaskedInput
              mask={maskCpfCnpj}
              placeholder="Cpf ou Cnpj"
              guide={false}
              type="text"
              value={cpfCnpj}
              onChange={(event) => setCpfCnpj(event.target.value)}
              required
            />

            {loading ? (
              <button type="submit" disabled>
                Consultando...
              </button>
            ) : (
              <button type="submit">Consultar</button>
            )}
          </Container>

        )
      }
    </Modal>
  );
}
