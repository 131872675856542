import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assets/prancheta1_13.png";
import logoGrayImg from "../../assets/prancheta1_13.png";
import { Container, Content } from "./styles";
import { RedirectTo } from "../../utils/redirectTo";

export function HeaderHome({ redirectTo }) {
  let location = useLocation();

  return (
    <Container id="topo">
      <Content>
        <Link to={redirectTo ? redirectTo : RedirectTo.login}>
          <img
            src={
              location.pathname === RedirectTo.serviceTerms ||
              location.pathname === RedirectTo.privacyPolicy
                ? logoGrayImg
                : logoImg
            }
            alt="Transitar Consultoria"
            className="logo"
          />
        </Link>

        <div>
          <Link to={RedirectTo.serviceTerms}>
            <button>Termos de Serviço</button>
          </Link>
          <Link to={RedirectTo.privacyPolicy}>
            <button>Política de Privacidade</button>
          </Link>
        </div>
      </Content>
    </Container>
  );
}
