import styled from "styled-components";

export const Container = styled.header`
  background: #4b6cb7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #182848,
    var(--blue),
    #4b6cb7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #182848,
    var(--blue),
    #4b6cb7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  }
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  padding: 2rem 1rem 12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;

    a {
      margin-bottom: 1rem;
    }
    
    }
    a img {
      height: 50px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #fff;
    background: var(--green);
    border: 0;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
    height: 3rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    img {
      margin-left: 10px;
   
      }
    }
  }
`;
