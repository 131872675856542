import styled from "styled-components";

export const Button = styled.button`
  position: fixed;
  left: 10px;
  bottom: 10px;
  width: 2.7rem;
  height: 2.7rem;
  text-align: center;
  color: #fff;
  background: #5a5c6980;
  border: none;
  border-radius:.70rem!important;
  
  :hover {
    background: #5a5c69;
  }
`;
