import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
// import successImg from '../../../assets/success.svg';
import { API_URL } from '../../../services/api';
import { Container } from './styles';

export function DeleteVehicleModal({
  isOpen,
  onRequestClose,
  vehicleId: id,
  vehicleLicensePlate: plate,
}) {
  const [requestSuccess, setRequestSuccess] = useState('');
  // const [loading, setLoading] = useState('');

  const handleDeleteVehicle = async (e) => {
    e.preventDefault();
    // setLoading(true);

    const res = await fetch(`${API_URL}/vehicles/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });

    const response = await res.json();
    // console.log(response);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);
    }

    // setLoading(false);
  };

  // if (requestSuccess !== '')
  //   return (
  //     <Modal
  //       isOpen={isOpen}
  //       onRequestClose={onRequestClose}
  //       overlayClassName="react-modal-overlay"
  //       className="react-modal-content"
  //     >
  //       <button
  //         type="button"
  //         onClick={onRequestClose}
  //         className="react-modal-close"
  //       >
  //         <img src={closeImg} alt="Fechar modal" />
  //       </button>

  //       <Result className="animeLeft">
  //         <h2>{requestSuccess}</h2>
  //         <img src={successImg} alt="Sucesso" onClick={onRequestClose} />
  //       </Result>
  //     </Modal>
  //   );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleDeleteVehicle}>
        <h2>Deletar Veículo</h2>
        {requestSuccess ? (
          <p>
            Veículo <span>{plate}</span> excluído com sucesso!
          </p>
        ) : (
          <p>
            Deseja mesmo excluir o veículo <span>{plate}</span>?
          </p>
        )}
        {requestSuccess ? (
          <button className="secondButton" onClick={onRequestClose}>
            Fechar
          </button>
        ) : (
          <>
            <button type="submit">Confirmar</button>
            <button className="secondButton" onClick={onRequestClose}>
              Cancelar
            </button>
          </>
        )}
      </Container>
    </Modal>
  );
}
