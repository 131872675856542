import styled from 'styled-components';

export const Container = styled.header`
  background: transparent;
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-width: 30em;
  }

  div {
    display: flex;

    a + a {
      margin-left: 10px;
    }

    button {
      display: block;
      width: 100%;
      padding: 0.5rem 1rem;
      height: auto;
      background: var(--background);
      color: var(--text-title);
      border-radius: 0.25rem;
      border: 0;
      font-size: 1rem;
      font-weight: 600;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;

    .logo {
      max-width: 26em;
    }

    a {
      margin-bottom: 1rem;
    }

    div {
      flex-direction: column;
      justify-content: center;

      a + a {
        margin-left: 0;
      }

      button {
        max-width: 190px;
        padding: 0.5rem;
      }
    }
  }
`;
