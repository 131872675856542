import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  div {
    padding: 0rem !important;
    background: var(--shape) !important;

    div {
      margin-top: 0rem !important;
      background: var(--shape) !important;
    }

    td > div {
      background: transparent !important;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px;
  border: none;
  background: transparent;
`;
