import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import addBlueImg from '../../../assets/add-blue.svg';
import removeBlueImg from '../../../assets/remove-blue.svg';
import successImg from '../../../assets/success.svg';
import pixImg from '../../../assets/pix.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import moneyImg from '../../../assets/money.svg';
// import trafficCopImg from '../../../assets/traffic-cop-icon-19.png'
import { maskCpfCnpj, maskQuantityCads, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import {
  Container,
  Result,
  RadioContainer,
  RadioContainerPayment,
  RadioBox,
  ValueContainer,
  IconButton,
  IconButtonContainer,
} from './styles';

export function SellCadsModal({ isOpen, onRequestClose, options }) {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [quantity, setQuantity] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [requestSuccess, setRequestSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // const profile = localStorage.getItem('profile');

  const handleQuantity = (operation) => {
    if (operation === 'add' && quantity < 999) {
      setQuantity(+quantity + 1);
    }

    if (operation === 'remove' && quantity > 1) {
      setQuantity(+quantity - 1);
    }
  };

  const handleSellCads = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (paymentMethod === '') {
      toast.error('Selecione uma forma de pagamento.');
      setLoading(false);
      return;
    }

    if (quantity === '0' || quantity === '00' || quantity === '000') {
      toast.error('Informe uma quantidade maior que zero.');
      setLoading(false);
      return;
    }

    let body = {};

    body = {
      cpfCnpj: unMask(cpfCnpj),
      quantity: +quantity,
      paymentMethod,
    };

    const res = await fetch(`${API_URL}/pdvs/cad-sale`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    // console.log(body);
    // console.log(response);

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setRequestSuccess(response.message);
    }

    setLoading(false);
  };

  // if (options?.educationalPeriod?.valid) {
  //   return (
  //     <Modal
  //       isOpen={isOpen}
  //       onRequestClose={onRequestClose}
  //       overlayClassName="react-modal-overlay"
  //       className="react-modal-content"
  //     >
  //       <button
  //         type="button"
  //         onClick={onRequestClose}
  //         className="react-modal-close"
  //       >
  //         <img src={closeImg} alt="Fechar modal" />
  //       </button>

  //       <Result className="animeLeft">
  //         <h2>Periodo Educativo</h2>
  //         <p className="animeLeft resultCard">
  //           {options?.educationalPeriod?.messageSale}
  //         </p>
  //         <img src={trafficCopImg} alt="Sucesso" onClick={onRequestClose} />
  //       </Result>
  //     </Modal>
  //   );
  // }

  if (requestSuccess !== '') {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button
          type="button"
          onClick={onRequestClose}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Fechar modal" />
        </button>

        <Result className="animeLeft">
          <h2>{requestSuccess}</h2>
          <img src={successImg} alt="Sucesso" onClick={onRequestClose} />
        </Result>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleSellCads}>
        <h2>Vender Cartões</h2>

        <MaskedInput
          mask={maskCpfCnpj}
          placeholder="Cpf ou Cnpj"
          guide={false}
          type="text"
          value={cpfCnpj}
          onChange={(event) => setCpfCnpj(event.target.value)}
          required
        />

        <RadioContainer>
          <RadioBox
            type="button"
            onClick={() => {
              setQuantity(10);
            }}
            isActive={quantity === 10}
            activeColor="green"
          >
            <span>
              <span>10</span> Cartões
            </span>
          </RadioBox>

          <RadioBox
            type="button"
            onClick={() => {
              setQuantity(20);
            }}
            isActive={quantity === 20}
            activeColor="green"
          >
            <span>
              <span>20</span> Cartões
            </span>
          </RadioBox>

          <RadioBox
            type="button"
            onClick={() => {
              setQuantity(30);
            }}
            isActive={quantity === 30}
            activeColor="green"
          >
            <span>
              <span>30</span> Cartões
            </span>
          </RadioBox>
        </RadioContainer>

        <>
          <MaskedInput
            mask={maskQuantityCads}
            placeholder="Outra quantidade"
            guide={false}
            type="text"
            value={quantity}
            onChange={(event) => setQuantity(event.target.value)}
            required
          />
          <IconButtonContainer>
            <IconButton type="button" onClick={() => handleQuantity('remove')}>
              <img src={removeBlueImg} alt="Menos" />
            </IconButton>

            <IconButton type="button" onClick={() => handleQuantity('add')}>
              <img src={addBlueImg} alt="Mais" />
            </IconButton>
          </IconButtonContainer>
        </>

        <ValueContainer>
          <h3>
            Valor total: <span>R$ {+quantity * 2},00</span>
          </h3>
        </ValueContainer>

        <RadioContainerPayment>
          <RadioBox
            type="button"
            onClick={() => {
              setPaymentMethod('money');
            }}
            isActive={paymentMethod === 'money'}
            activeColor="green"
          >
            <img src={moneyImg} alt="Dinheiro" />
            <span>Dinheiro</span>
          </RadioBox>

          <RadioBox
            type="button"
            onClick={() => {
              setPaymentMethod('pix');
            }}
            isActive={paymentMethod === 'pix'}
            activeColor="green"
          >
            <img src={pixImg} alt="Pix" />
            <span>Pix</span>
          </RadioBox>

          <RadioBox
            type="button"
            onClick={() => {
              setPaymentMethod('debit_card');
            }}
            isActive={paymentMethod === 'debit_card'}
            activeColor="green"
          >
            <img src={creditCardImg} alt="Débito" />
            <span>Débito</span>
          </RadioBox>

          <RadioBox
            type="button"
            onClick={() => {
              setPaymentMethod('credit_card');
            }}
            isActive={paymentMethod === 'credit_card'}
            activeColor="green"
          >
            <img src={creditCardImg} alt="Crédito" />
            <span>Crédito</span>
          </RadioBox>
        </RadioContainerPayment>

        {loading ? (
          <button type="submit" disabled>
            Vendendo
          </button>
        ) : (
          <button type="submit">Vender</button>
        )}
      </Container>
    </Modal>
  );
}
