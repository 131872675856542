import appFtz01 from './app-ftz-01.png'
import appFtz02 from './app-ftz-02.png'
import appFtz03 from './app-ftz-03.png'
import appFtz04 from './app-ftz-04.png'
import appIcon from './app-icon.png'
import appStore from './app-store.png'
import ativo16 from './ativo-16.svg'
import bgPhoto from './bg-photo.png'
import googlePlay from './google-play.png'
import prancheta113 from './prancheta1_13.png'
import ativo192x from './ativo-19-2x.png'

export const Images = {
  appFtz01,
  appFtz02,
  appFtz03,
  appFtz04,
  appIcon,
  appStore,
  ativo16,
  bgPhoto,
  googlePlay,
  prancheta113,
  ativo192x
}