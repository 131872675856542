import {
  faChevronUp,
  faMobileScreen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Columns, Container, Footer } from "react-bulma-components";
import { Images } from "../../pages/HomePage/images";
import "./FooterHome.css";

export const FooterHome = () => {
  return (
    <Footer className="footerhome">
      <Container>
        <Columns>
          <Columns.Column size={2}>
            <img src={Images.ativo16} alt="" className="img-ativo-192x" />
          </Columns.Column>
          <Columns.Column style={{ padding: "1rem" }}>
            <p>
              { process.env.REACT_APP_COMPANY }
              <br />
              { process.env.REACT_APP_COMPANY_ADDRESS }
              <br />
              CEP: { process.env.REACT_APP_COMPANY_ZIPCOD }
              <br />
              <FontAwesomeIcon icon={faPhone} /> { process.env.REACT_APP_COMPANY_CONTACT_1 } |{" "}
              <FontAwesomeIcon icon={faMobileScreen} /> { process.env.REACT_APP_COMPANY_CONTACT_2 }
            </p>
          </Columns.Column>
          <Columns.Column textAlign={"right"}>
            <a href="#topo" className="topo">
              <FontAwesomeIcon icon={faChevronUp} />
            </a>
          </Columns.Column>
        </Columns>
      </Container>
    </Footer>
  );
};
