import LayoutHomePage from '../LayoutHomePage';
import { ActivateForm } from '../../components/Forms/Activate';

export default function ActivatePage() {
  return (
    <LayoutHomePage>
      <ActivateForm />
    </LayoutHomePage>
  );
}
