import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { maskPhone, unMask } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { API_URL } from '../../../services/api';
import { Container, InputGrid } from './styles';
import { PasswordInput } from '../../PasswordInput';

export function UserModal({ isOpen, onRequestClose, userData }) {
  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState('');

  const profile = localStorage.getItem('profile');

  useEffect(() => {
    const birthDay = moment(userData.birthDay, 'DD/MM/YYYY');
    const birthdayDate = birthDay.format('YYYY-MM-DD');

    setName(userData.name);
    setBirthday(birthdayDate);
    setPhone(userData.phone);
    setEmail(userData.email);
    setPassword('');
    setNewPassword('');
  }, [userData]);

  const today = new Date();
  const year = today.getFullYear();
  const minYear = +year - 125;
  let maxYear = +year - 18;

  if (profile === 'pdv') {
    maxYear = +year;
  }

  const handleEditUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const birthdayYear = birthday.substring(0, 4);

    if (
      birthdayYear < minYear.toString() ||
      birthdayYear > maxYear.toString()
    ) {
      toast.error('Data de nascimento inválida!');
      setLoading(false);
      return;
    }

    let body = {};

    if (password || newPassword !== '') {
      body = {
        name,
        birthday,
        phone: `+55${unMask(phone)}`,
        email,
        password,
        newPassword,
      };
    } else {
      body = {
        name,
        birthday,
        phone: `+55${unMask(phone)}`,
        email,
      };
    }

    const res = await fetch(`${API_URL}/users`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setLoading(false);
      setTimeout(onRequestClose, 3000);
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleEditUser}>
        <h2>Editar Usuário</h2>

        <input
          placeholder="Nome ou Razão Social"
          type="text"
          maxLength="60"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <InputGrid>
          <input
            placeholder="Data de nascimento"
            type="date"
            min={`${minYear}-12-31`}
            max={`${maxYear}-12-31`}
            value={birthday}
            onChange={(event) => setBirthday(event.target.value)}
          />

          <MaskedInput
            mask={maskPhone}
            placeholder="Telefone"
            guide={false}
            type="tel"
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </InputGrid>
        <input
          placeholder="E-mail"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <InputGrid>
          {newPassword ? (
            <PasswordInput
              placeholder="Senha Atual"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              minLength={6}
              required
            />
          ) : (
            <PasswordInput
              placeholder="Senha Atual"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              minLength={6}
            />
          )}

          {password ? (
            <PasswordInput
              placeholder="Nova Senha"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              minLength={6}
              required
            />
          ) : (
            <PasswordInput
              placeholder="Nova Senha"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              minLength={6}
            />
          )}
        </InputGrid>

        {loading ? (
          <button type="submit" disabled>
            Salvando
          </button>
        ) : (
          <button type="submit">Salvar</button>
        )}
      </Container>
    </Modal>
  );
}
