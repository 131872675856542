import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { API_URL } from '../../../services/api';
import { Container } from './styles';
import moment from 'moment';

export function CancelScheduleParkingModal({ isOpen, onRequestClose, vehicleSchedule }) {
  const [loading, setLoading] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!window.confirm('Confirma o cancelamento ?')) return

    setLoading(true)

    const res = await fetch(`${API_URL}/parkings/schedule/${vehicleSchedule?.licensePlate}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      }
    });

    const response = await res.json();
    setLoading(false)
    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(onRequestClose, 3000);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleSubmit}>
        <h2>Cancelar Agendamento: {vehicleSchedule?.licensePlate} </h2>

        <h3>Regra: {vehicleSchedule?.ruleName}</h3>

        <h3>Inicio: {moment(vehicleSchedule?.startDate).format("DD/MM/YYYY HH:mm")}</h3>

        {loading ? (
          <button className="secondButton" disabled>
            CANCELANDO ...
          </button>
        ) : (
          <button className="secondButton">
            CANCELAR AGENDAMENTO
          </button>
        )}
      </Container>
    </Modal>
  );
}
