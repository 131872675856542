import { useState, useEffect } from 'react';
import { API_URL } from '../../services/api';
import { Balance } from '../Balance';
import { HistoryTransactions } from '../HistoryTransactions';
import { HistorySells } from '../HistorySells';
import { HistoryUse } from '../HistoryUse';
import { User } from '../User';
import { Vehicles } from '../Vehicles';
import moment from 'moment';
import { cpfOrCnpjMask, celPhoneMask } from '../../utils/masks';
import { UserModal } from '../Modals/UserModal';
import { VehicleModal } from '../Modals/VehicleModal';
import { CreateNewUserModal } from '../Modals/CreateNewUserModal';
import { SearchCadsUserModal } from '../Modals/SearchCadsUserModal';
import { CadsModal } from '../Modals/CadsModal';
import { SellCadsModal } from '../Modals/SellCadsModal';
import { ParkingModal } from '../Modals/ParkingModal';
import { Container, Group } from './styles';
import { VehiclesParked } from '../VehiclesParked';
import { CreateNewUser } from '../CreateNewUser';
import { SearchCadsUser } from '../SearchCadsUser';
import useMedia from '../../hooks/useMedia';
import { useFetch } from '../../hooks/useFetch';

export function Dashboard() {
  //const [loading, setLoading] = useState(null);
  const mobile = useMedia('(max-width: 768px)');
  const [userData, setUserData] = useState({});
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [vehiclesData, setVehiclesData] = useState([]);
  const [vehiclesParkedData, setVehiclesParkedData] = useState([]);
  const [cadsData, setCadsData] = useState([]);

  const [reloadHistoryUse, setReloadHistoryUse] = useState('');
  const [reloadHistoryTransact, setReloadHistoryTransact] = useState('');
  const [reloadHistorySells, setReloadHistorySells] = useState('');

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [isCadsModalOpen, setIsCadsModalOpen] = useState(false);
  const [isSellCadsModalOpen, setIsSellCadsModalOpen] = useState(false);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState(false);
  const [isSearchCadsUserModalOpen, setIsSearchCadsUserModalOpen] =
    useState(false);
  const [isCreateNewUserModalOpen, setIsCreateNewUserModalOpen] =
    useState(false);
  const [educationalPeriod, setEducationalPeriod] = useState({})

  const profile = localStorage.getItem('profile');

  const { response: responseTimeRuleToday } =
    useFetch(`${API_URL}/time-rule/today`)

  const timeRuleToday = responseTimeRuleToday?.data || {}

  const { response: responseScheduleParking, refresh: refreshScheduleParking } =
    useFetch(`${API_URL}/parkings/schedule/active`)
  
  const vehiclesSchedule = responseScheduleParking?.data || []

  useEffect(() => {
    fetchEducationalPeriod();
    fetchUser();
    fetchVehicles();
    fetchVehiclesParked();
    fetchCads();
  }, []);

  const fetchEducationalPeriod = async () => {
    const res = await fetch(`${API_URL}/educational-period`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });
    const response = await res.json();
    setEducationalPeriod(response?.data);
  };

  async function fetchUser() {
    const id = window.localStorage.getItem('id');
    const res = await fetch(`${API_URL}/users/${id}`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });

    const response = await res.json();
    const body = response.data;

    const cpfCnpjMasked = cpfOrCnpjMask(body.cpf_cnpj);
    body.cpf_cnpj = cpfCnpjMasked;
    const phoneMasked = celPhoneMask(body.phone.replace('+55', ''));
    body.phone = phoneMasked;
    const birthDay = moment(body.birthDay, 'YYYY-MM-DD');
    const birthdayMasked = birthDay.format('DD/MM/YYYY');
    body.birthDay = birthdayMasked;

    setUserData(body);
  }

  async function fetchVehicles() {
    const res = await fetch(`${API_URL}/vehicles`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });
    const response = await res.json();
    const body = response.data;
    setVehiclesData(body);
  }

  async function fetchVehiclesParked() {
    const res = await fetch(`${API_URL}/parkings`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });
    const response = await res.json();
    const body = response.data;
    setVehiclesParkedData(body);
  }

  async function fetchCads() {
    const res = await fetch(`${API_URL}/transactions`, {
      method: 'GET',
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
    });
    const response = await res.json();
    const body = response.data;
    setCadsData(body.cads);
  }

  // User
  function handleOpenUserModal() {
    setIsUserModalOpen(true);
  }

  function handleCloseUserModal() {
    setIsUserModalOpen(false);
    fetchUser();
  }

  // CreateNewUser
  function handleOpenCreateNewUserModal() {
    setIsCreateNewUserModalOpen(true);
  }

  function handleCloseCreateNewUserModal() {
    setIsCreateNewUserModalOpen(false);
  }

  // SearchCadsUser
  function handleOpenSearchCadsUserModal() {
    setIsSearchCadsUserModalOpen(true);
  }

  function handleCloseSearchCadsUserModal() {
    setIsSearchCadsUserModalOpen(false);
  }

  // Vehicle
  function handleOpenVehicleModal(vehicle) {
    setVehicleSelected(vehicle)
    setIsVehicleModalOpen(true)
  }

  function handleCloseVehicleModal() {
    setIsVehicleModalOpen(false);
    fetchVehicles();
  }

  // Cads
  function handleOpenCadsModal() {
    setIsCadsModalOpen(true);
  }

  function handleCloseCadsModal() {
    setIsCadsModalOpen(false);
    fetchCads();
    setReloadHistoryTransact(moment().format())
  }

  // Sell Cads
  function handleOpenSellCadsModal() {
    setIsSellCadsModalOpen(true);
  }

  function handleCloseSellCadsModal() {
    setIsSellCadsModalOpen(false);
    fetchCads();
    setReloadHistorySells(moment().format())
  }

  // Parking
  function handleOpenParkingModal() {
    setIsParkingModalOpen(true);
  }

  function handleCloseParkingModal() {
    setIsParkingModalOpen(false);
    fetchCads();
    setReloadHistoryUse(moment().format())
    setReloadHistorySells(moment().format())
    fetchVehiclesParked();
  }

  return (
    <Container>
      <UserModal
        isOpen={isUserModalOpen}
        onRequestClose={handleCloseUserModal}
        userData={userData}
      />

      <VehicleModal
        isOpen={isVehicleModalOpen}
        onRequestClose={handleCloseVehicleModal}
        vehicle={vehicleSelected}
      />

      <CreateNewUserModal
        isOpen={isCreateNewUserModalOpen}
        onRequestClose={handleCloseCreateNewUserModal}
      />

      <SearchCadsUserModal
        isOpen={isSearchCadsUserModalOpen}
        onRequestClose={handleCloseSearchCadsUserModal}
      />

      <CadsModal
        isOpen={isCadsModalOpen}
        onRequestClose={handleCloseCadsModal}
        options={{educationalPeriod}}
      />

      <SellCadsModal
        isOpen={isSellCadsModalOpen}
        onRequestClose={handleCloseSellCadsModal}
        options={{educationalPeriod}}
      />

      <ParkingModal
        isOpen={isParkingModalOpen}
        onRequestClose={handleCloseParkingModal}
      />

      <Group className="animeLeft">
        <User userData={userData} onOpenUserModal={handleOpenUserModal} />

        {mobile && (
          <Balance
            cadsData={cadsData}
            onOpenCadsModal={handleOpenCadsModal}
            onOpenSellCadsModal={handleOpenSellCadsModal}
            onOpenParkingModal={handleOpenParkingModal}
            fetchCads={fetchCads}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            refreshScheduleParking={refreshScheduleParking}
          />
        )}

        {profile === 'user' && (
          <Vehicles
            vehiclesData={vehiclesData}
            onOpenVehicleModal={handleOpenVehicleModal}
            fetchVehicles={fetchVehicles}
            onOpenParkingModal={handleOpenParkingModal}
            vehiclesParkedData={vehiclesParkedData}
            fetchVehiclesParked={fetchVehiclesParked}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            vehiclesSchedule={vehiclesSchedule}
            refreshScheduleParking={() => {
              refreshScheduleParking()
              fetchCads()
            }}
          />
        )}

        {profile === 'pdv' && (
          <CreateNewUser
            onOpenCreateNewUserModal={handleOpenCreateNewUserModal}
          />
        )}

        {profile === 'pdv' && (
          <SearchCadsUser
            onOpenSearchCadsUserModal={handleOpenSearchCadsUserModal}
          />
        )}

        {!mobile && profile === 'pdv' && (
          <VehiclesParked
            vehiclesParkedData={vehiclesParkedData}
            fetchVehiclesParked={fetchVehiclesParked}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            vehiclesSchedule={vehiclesSchedule}
            refreshScheduleParking={() => {
              refreshScheduleParking()
              fetchCads()
            }}
          />
        )}
      </Group>

      <Group className="animeLeft">
        {mobile && profile === 'pdv' && (
          <VehiclesParked
            vehiclesParkedData={vehiclesParkedData}
            fetchVehiclesParked={fetchVehiclesParked}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            vehiclesSchedule={vehiclesSchedule}
            refreshScheduleParking={() => {
              refreshScheduleParking()
              fetchCads()
            }}
          />
        )}
        {!mobile && (
          <Balance
            cadsData={cadsData}
            onOpenCadsModal={handleOpenCadsModal}
            onOpenSellCadsModal={handleOpenSellCadsModal}
            onOpenParkingModal={handleOpenParkingModal}
            fetchCads={fetchCads}
            validTimeForParking={timeRuleToday?.validTimeForParking}
            refreshScheduleParking={refreshScheduleParking}
          />
        )}
        <HistoryUse reload={reloadHistoryUse} />
        <HistoryTransactions reload={reloadHistoryTransact} />
        {profile === 'pdv' && (
          <HistorySells reload={reloadHistorySells} />
        )}
      </Group>
    </Container>
  );
}
