import { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { DetailsHistoryTransactionModal } from '../Modals/DetailsHistoryTransactionModal';
import InfoImg from '../../assets/info.svg';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Container, IconButton } from './styles';
import { useFetch } from '../../hooks/useFetch';
import { API_URL } from '../../services/api';
import { Button, Icon } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

export function HistoryTransactions({ reload }) {
  const [viewAll, setViewAll] = useState(false)
  const [
    isDetailsHistoryTransactionModalOpen,
    setIsDetailsHistoryTransactionModalOpen,
  ] = useState(false);
  const [detailsData, setDetailsData] = useState({});

  const { response, load } = useFetch(`${API_URL}/historic-payments?p=${reload}${viewAll ? '&all=true' : ''}`)

  const historyTransactionsData = response?.data?.payments || []

  function handleOpenDetailsHistoryTransactionModal() {
    setIsDetailsHistoryTransactionModalOpen(true);
  }

  function handleCloseDetailsHistoryTransactionModal() {
    setIsDetailsHistoryTransactionModalOpen(false);
    setDetailsData({});
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    // overrides: {
    //   MUIDataTableToolbar: { root: { display: 'none' } },
    // },
  });

  theme.shadows = [];

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'date',
      label: 'Data e hora',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'quantity',
      label: 'Cartões',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'transactionId',
      label: 'Id da Transação',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'Detalhes',
      label: 'Detalhes',
      options: {
        filter: false,
        sort: false,
        display: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={() => {
                setDetailsData(historyTransactionsData.find(
                  history => history.id === tableMeta.rowData[0]
                ));
                handleOpenDetailsHistoryTransactionModal();
              }}
            >
              <img src={InfoImg} alt="Mais informações" />
            </IconButton>
          );
        },
      },
    },
  ];

  const data = [];
  for (var i = 0; i < historyTransactionsData.length; i++) {
    data.push({
      id: historyTransactionsData[i].id,
      date: historyTransactionsData[i].date,
      quantity: historyTransactionsData[i].quantity,
      transactionId: historyTransactionsData[i].transactionId,
    });
  }

  const options = {
    responsive: 'standard',
    draggableColumns: {
      enabled: false,
    },
    selectableRows: 'none',
    rowsPerPage: 2,
    rowsPerPageOptions: [],
    print: 'false',
    filter: 'false',
    download: 'false',
    viewColumns: 'false',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        noMatch: 'Nenhum registro encontrado.',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'resultado(s) de', // 1-10 of 30
      },
      toolbar: {
        search: 'Pesquisar',
        viewColumns: 'Selecionar Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        title: 'FILTROS',
        reset: 'limpar',
        all: 'Todos',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas da Tabela',
      },
    },
    customToolbarSelect: () => {},
    expandableRows: false,
    expandableRowsOnClick: false,
  };

  return (
    <Container>
      <DetailsHistoryTransactionModal
        isOpen={isDetailsHistoryTransactionModalOpen}
        onRequestClose={handleCloseDetailsHistoryTransactionModal}
        detailsData={detailsData}
      />

      <MuiThemeProvider theme={theme}>
        <p className='mt-1 is-flex is-align-items-center'>
          <span>
            {viewAll && !load ? '* Está sendo exibido o histórico completo' : '* Histórico dos últimos 90 dias'}
          </span>
          {
            load && (
              <Icon className='fa-spin'>
                <FontAwesomeIcon icon={faSyncAlt} />
              </Icon>
            )
          }
          {
            !viewAll && (
              <Button 
                type='button' 
                className='ml-2' 
                size='small' 
                color='primary'
                rounded
                onClick={() => setViewAll(true)}
              >
                Ver Tudo
              </Button>
            )
          }
        </p>
        <MUIDataTable
          title="Histórico de compras"
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
}
