import { useState } from 'react';
import BuyImg from '../../assets/buy.svg';
import RefreshImg from '../../assets/refresh.svg';
import { Container, IconButton, Flex } from './styles';
import { Button, Dropdown, Icon } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faDollarSign, faEllipsisV, faMapMarkerAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { ScheduleParkingModal } from '../Modals/ScheduleParkingModal';

export function Balance({
  cadsData,
  onOpenCadsModal,
  onOpenSellCadsModal,
  onOpenParkingModal,
  fetchCads,
  validTimeForParking,
  refreshScheduleParking
}) {
  const [isScheduleParkingModalOpen, setIsScheduleParkingModalOpen] = useState(false)

  const profile = localStorage.getItem('profile');

  const toggleScheduleParkingModal = () => {
    if(isScheduleParkingModalOpen) {
      refreshScheduleParking()
      fetchCads()
    }
    setIsScheduleParkingModalOpen(!isScheduleParkingModalOpen)
  }

  return (
    <Container>
      <header style={{ alignItems:'start' }}>
        <Flex>
          <h2>Saldo</h2>{' '}
          <IconButton onClick={() => window.location.reload()}>
            <img src={RefreshImg} alt="Atualizar" />
          </IconButton>
        </Flex>
        {
          profile === 'user' && (
            <button type="button" className="buy" onClick={onOpenCadsModal}>
              Comprar
              <img src={BuyImg} alt="Comprar" />
            </button>
          )
        }
        {
          profile === 'pdv' && (
            <Dropdown align='right'>
              <Dropdown.Trigger>
                <Button size='small'>
                  <Icon size="small">
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </Icon>
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Menu>
                <Dropdown.Content>
                  <Dropdown.Item onClick={onOpenCadsModal}>
                    <FontAwesomeIcon icon={faShoppingCart} className="mr-2" /> Comprar
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={onOpenSellCadsModal}>
                    <FontAwesomeIcon icon={faDollarSign} className="mr-2" /> Vender
                  </Dropdown.Item>
                  {
                    validTimeForParking && (
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onOpenParkingModal}>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> Estacionar
                        </Dropdown.Item>
                      </>
                    )
                  }
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={toggleScheduleParkingModal}>
                    <FontAwesomeIcon icon={faClock} className="mr-2" /> Agendar
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Menu>
            </Dropdown>
          )
        }
      </header>
      <header>
        <strong>
          <span>{cadsData}</span> {cadsData === 1 ? 'Cartão' : 'Cartões'}
          <p>ou R$ {cadsData * 2},00</p>
        </strong>

        {/* {profile === 'pdv' && (
          <button
            className="second"
            type="button"
            onClick={onOpenSellCadsModal}
          >
            Vender
            <img src={SellImg} alt="Vender" />
          </button>
        )} */}
      </header>

      {/* {profile === 'pdv' && (
        <header className="parking">
          <button className="third" type="button" onClick={onOpenParkingModal}>
            Estacionar
            <img src={GpsImg} alt="Estacionar" />
          </button>
        </header>
       )}  */}

       <ScheduleParkingModal
        isOpen={isScheduleParkingModalOpen}
        onRequestClose={toggleScheduleParkingModal}
      />
    </Container>
  );
}
