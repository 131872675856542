import { useState } from 'react';
import AddImg from '../../assets/add.svg';
import CarImg from '../../assets/car.svg';
import BusImg from '../../assets/bus.svg';
import TruckImg from '../../assets/truck.svg';
import MotocycleImg from '../../assets/motorcycle.svg';
import { Container, IconButton } from './styles';
import { DeleteVehicleModal } from '../Modals/DeleteVehicleModal';
import Timer from 'react-compound-timer';
import { RenewParkingModal } from '../Modals/RenewParkingModal';
import { Button, Dropdown, Icon, Notification } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEdit, faEllipsisV, faMapMarkerAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ScheduleParkingModal } from '../Modals/ScheduleParkingModal';
import moment from 'moment';
import { CancelScheduleParkingModal } from '../Modals/CancelScheduleParkingModal';

export function Vehicles({
  vehiclesData: vehicles,
  onOpenVehicleModal,
  fetchVehicles,
  onOpenParkingModal,
  vehiclesParkedData: vehiclesParked,
  fetchVehiclesParked,
  validTimeForParking,
  vehiclesSchedule,
  refreshScheduleParking
}) {
  const [isDeleteVehicleModalOpen, setIsDeleteVehicleModalOpen] = useState(false)
  const [isScheduleParkingModalOpen, setIsScheduleParkingModalOpen] = useState(false)
  const [isCancelScheduleModalOpen, setIsCancelScheduleModalOpen] = useState(false)
  const [vehicleId, setVehicleId] = useState('');
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState('');
  const [isRenewParkingModalOpen, setIsRenewParkingModalOpen] = useState(false);

  // const { response: responseScheduleParking, refresh: refreshScheduleParking } =
  //   useFetch(`${API_URL}/parkings/schedule/active`)
    
  // const vehiclesSchedule = responseScheduleParking?.data || []

  const toggleRenewParkingModal = () => {
    if(isRenewParkingModalOpen) fetchVehiclesParked()
    setIsRenewParkingModalOpen(!isRenewParkingModalOpen)
  }

  const toggleDeleteVehicleModal = () => {
    if(isDeleteVehicleModalOpen) fetchVehicles()
    setIsDeleteVehicleModalOpen(!isDeleteVehicleModalOpen)
  }

  const toggleScheduleParkingModal = () => {
    if(isScheduleParkingModalOpen) refreshScheduleParking()
    setIsScheduleParkingModalOpen(!isScheduleParkingModalOpen)
  }

  const toggleCancelScheduleModal = () => {
    if(isCancelScheduleModalOpen) refreshScheduleParking()
    setIsCancelScheduleModalOpen(!isCancelScheduleModalOpen)
  }

  const ScheduleButton = ({ licensePlate, schedule }) => {
    return (
      <div className='is-flex m-0 p-0 ml-1' style={{width:'100%'}}>
        <Button
          size='small'
          fullwidth
          style={{ background:'var(--green)', color:'var(--shape)', borderRadius:'0.25rem', fontSize:'0.9rem', fontWeight:'500' }}
          title={ schedule ? 'Inicio: '+moment(schedule.startDate).format('DD/MM HH:mm') : ''}
          onClick={() => {
            if (schedule) {
              toggleCancelScheduleModal()
              setVehicleLicensePlate(licensePlate)
            } else {
              toggleScheduleParkingModal()
              sessionStorage.setItem('licensePlate', licensePlate)
            }
          }}
        >
          {
            schedule 
              ? 
              <>
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                {moment(schedule.startDate).format('DD/MM HH:mm')}
              </>
              : 'AGENDAR'
          }
        </Button>
      </div>
    )
  }

  const ParkingButton = ({ vehicleParked, licensePlate }) => {
    return (
      <div className='is-flex m-0 p-0 mr-1' style={{width:'100%'}}>
        {
          vehicleParked ? (
            <strong className="timer" style={{backgroundColor:'var(--blue)', color:'var(--shape)'}}>
              <Timer
                initialTime={vehicleParked.timeReaming}
                direction="backward"
                checkpoints={[{
                  time: 0, callback: () => window.location.reload()
                }]}
              >
                {
                vehicleParked.timeReaming !== 0 &&
                  <>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
                    <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                  </>
                }
              </Timer>
            </strong>
          ) : (
            <Button
              size='small'
              fullwidth
              style={{ background:'var(--blue)', color:'var(--shape)', borderRadius:'0.25rem', fontSize:'0.9rem', fontWeight:'500' }}
              onClick={() => {
                onOpenParkingModal()
                sessionStorage.setItem('licensePlate', licensePlate)
              }}
            >
              ESTACIONAR
            </Button>
          )
        }
      </div>
    )
  }

  const Vehicle = ({ vehicle, vehicleParked, vehicleSchedule, validTimeForParking }) => {
    return (
      <div className='vehicle is-flex is-flex-direction-column py-3 px-4'>
        <div className='is-flex m-0 p-0' style={{width:'100%'}}>
          { vehicle.type === 'carro' && <img src={CarImg} alt={vehicle.type} /> }
          { vehicle.type === 'ônibus' && <img src={BusImg} alt={vehicle.type} /> }
          { vehicle.type === 'caminhão' && <img src={TruckImg} alt={vehicle.type} /> }
          { vehicle.type === 'motocicleta' && <img src={MotocycleImg} alt={vehicle.type} /> }
          <span>
            <strong style={{lineHeight:'1.4rem'}}>
              { vehicle.licensePlate }
            </strong>
            <p style={{marginTop:0, fontWeight:500}}>
              { vehicle.alias }
            </p>
          </span>
          {
            vehicleParked ? (
              <IconButton
                onClick={() => {
                  toggleRenewParkingModal();
                  setVehicleLicensePlate(vehicleParked.licensePlate);
                }}
              >
                <img src={AddImg} alt="Mais opções" title='Mais opções' />
              </IconButton>
            ) : (
              <Dropdown align='right'>
                <Dropdown.Trigger>
                  <Button size='small'>
                    <Icon size="small">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Icon>
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => onOpenVehicleModal(vehicle)}>
                      <FontAwesomeIcon icon={faEdit} className="mr-2" /> Editar veículo
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        toggleDeleteVehicleModal();
                        setVehicleId(vehicle.id);
                        setVehicleLicensePlate(vehicle.licensePlate);
                      }}
                    >
                      <FontAwesomeIcon className="mr-2" icon={faTrash} color='var(--red)' /> Excluir veículo
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
        </div>
        <div className='is-flex m-0 p-0 mt-2' style={{width:'100%'}}>
          {
            validTimeForParking && (
              <ParkingButton licensePlate={vehicle.licensePlate} vehicleParked={vehicleParked} />
            )
          }
          <ScheduleButton licensePlate={vehicle.licensePlate} schedule={vehicleSchedule} />
        </div>
      </div>
    )
  }

  return (
    <Container>
      <DeleteVehicleModal
        isOpen={isDeleteVehicleModalOpen}
        onRequestClose={toggleDeleteVehicleModal}
        vehicleId={vehicleId}
        vehicleLicensePlate={vehicleLicensePlate}
      />

      <RenewParkingModal
        isOpen={isRenewParkingModalOpen}
        onRequestClose={toggleRenewParkingModal}
        vehicleLicensePlate={vehicleLicensePlate}
        vehicleParkedData={vehiclesParked?.find(vp => vp.licensePlate === vehicleLicensePlate)}
      />

      <ScheduleParkingModal
        isOpen={isScheduleParkingModalOpen}
        onRequestClose={toggleScheduleParkingModal}
      />

      <CancelScheduleParkingModal
        isOpen={isCancelScheduleModalOpen}
        onRequestClose={toggleCancelScheduleModal}
        vehicleSchedule={vehiclesSchedule?.find(vs => vs.licensePlate === vehicleLicensePlate)}
      />

      <header>
        <h2>Meus veículos</h2>
        <IconButton onClick={onOpenVehicleModal}>
          <img src={AddImg} alt="Adicionar" />
        </IconButton>
      </header>

      {
        validTimeForParking === false && (
          <Notification color='dark'>
            O estacionamento neste horário é livre.<br/>Você pode <b>AGENDAR</b> seu estacionamento 
            para o próximo dia e horário de funcionamento do Zona Azul.
          </Notification>
        )
      }

      {
        vehicles?.map(vehicle => (
          <Vehicle
            key={vehicle.licensePlate}
            vehicle={vehicle}
            vehicleParked={vehiclesParked?.find(vp => vp.licensePlate === vehicle.licensePlate)}
            vehicleSchedule={vehiclesSchedule?.find(vs => vs.licensePlate === vehicle.licensePlate)}
            validTimeForParking={validTimeForParking}
          />
        ))
      }

      {
        !vehicles && <p>Nenhum veículo cadastrado.</p>
      }
    </Container>
  );
}
