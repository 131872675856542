import moment from 'moment';

export const getDiffTime = (startTime, endTime) => (
  moment.utc( moment(endTime||moment()).diff(moment(startTime)) ).format("HH:mm:ss")
)

export const convertPercentToDecimal = (value) => (
  (value) ? value / 100 : null
)

export const convertDecimalToPercent = (value) => (
  (value) ? value * 100 : null
)

export const toCurrency = (value) => {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const toNumber = (value) => {
  return value.toLocaleString('pt-br')
}

export const toDecimal = (value) => {
  return value.toLocaleString('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })
}
