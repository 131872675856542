import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  header + div {
    margin-top: 1rem;
  }

  div {
    padding: 1rem 1.5rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      display: flex;
      justify-content: center;
    }
  }

  div + div {
    margin-top: 1rem !important;
  }

  .timer {
    width: 100px;
    background: var(--green);
    border-radius: 0.25rem;
  }
`;

export const IconButton = styled.button`
  height: 34px;
  padding: 8px;
  border: none;
  background: transparent;
`;
