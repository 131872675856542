import { ResetPassword } from '../../components/Forms/ResetPassword';
import LayoutHomePage from '../LayoutHomePage';

export default function ResetPasswordPage() {
  return (
      <LayoutHomePage>
          <ResetPassword />
      </LayoutHomePage>
  );
}
