export const RedirectTo = {
  notFound: '*',
  home: '/',
  login: '/login',
  logout: '/logout',
  register: '/register',
  activate: '/activate',
  resendCode: '/resendCode',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  serviceTerms: '/serviceTerms',
  privacyPolicy: '/privacyPolicy',
  dashboard: '/dashboard',
}
