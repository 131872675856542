import { Link } from 'react-router-dom';
import { Header } from '../../components/Header';
import { RedirectTo } from '../../utils/redirectTo';
import { Container } from './styles';

export default function DashboardPage() {
  return (
    <>
      <Header />
      <Container>
        <h1>Página não encontrada!</h1>
        <p>
          Voltar para:{' '}
          <span>
            <Link to={RedirectTo.login}>Home</Link>
          </span>{' '}
          |{' '}
          <span>
            <Link to={RedirectTo.dashboard}>Dashboard</Link>
          </span>
        </p>
      </Container>
    </>
  );
}
