import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { HomePage } from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import Logout from './components/Forms/Logout';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ServiceTermsPage from './pages/ServiceTermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ActivatePage from './pages/ActivatePage';
import ResendCodePage from './pages/ResendCodePage';
import ProtectedRoute from './helpers/ProtectedRoute';
import { RedirectTo } from './utils/redirectTo';

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path={RedirectTo.home} component={HomePage} />
          <Route exact path={RedirectTo.login} component={LoginPage} />
          <Route exact path={RedirectTo.logout} component={Logout} />
          <Route exact path={RedirectTo.register} component={RegisterPage} />
          <Route exact path={RedirectTo.activate} component={ActivatePage} />
          <Route exact path={RedirectTo.resendCode} component={ResendCodePage} />
          <Route exact path={RedirectTo.forgotPassword} component={ForgotPasswordPage} />
          <Route exact path={RedirectTo.resetPassword} component={ResetPasswordPage} />
          <Route exact path={RedirectTo.serviceTerms} component={ServiceTermsPage} />
          <Route exact path={RedirectTo.privacyPolicy} component={PrivacyPolicyPage} />
          <ProtectedRoute exact path={RedirectTo.dashboard} component={DashboardPage} />

          <Route path={RedirectTo.notFound} component={NotFoundPage} />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
