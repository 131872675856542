import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import { Container, InfoContainer } from './styles';
import Loading from '../../../helpers/Loading';

export function DetailsHistoryTransactionModal({
  isOpen,
  onRequestClose,
  detailsData,
}) {
  if (Object.keys(detailsData).length === 0)
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <Loading />
      </Modal>
    );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container>
        <h2>Detalhes da transação</h2>

        <InfoContainer>
          <p>
            Data e hora
            <br />
            <span>{detailsData.date}</span>
          </p>
          <p>
            Forma de pagamento
            <br />
            <span>{detailsData.payment}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Quantidade de Cartões
            <br />
            <span>{detailsData.quantity}</span>
          </p>
          <p>
            Valor em reais
            <br />
            <span>{detailsData.value}</span>
          </p>
        </InfoContainer>

        <InfoContainer>
          <p>
            Código da transação
            <br />
            <span>{detailsData.transactionId}</span>
          </p>
          <p>
            Concessionária
            <br />
            <span>{detailsData.distributorData.corporateName}</span>
          </p>
        </InfoContainer>
      </Container>
    </Modal>
  );
}
