import { ForgotPassword } from '../../components/Forms/ForgotPassword';
import LayoutHomePage from '../LayoutHomePage';

export default function ForgotPasswordPage() {
  return (
    <LayoutHomePage>
      <ForgotPassword />
    </LayoutHomePage>
  );
}
