import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.form`
  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  h3 {
    color: var(--text-title);
    font-size: 1.3rem;
    /* margin: 1.4rem 0; */

    span {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--blue);
    }
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    /* & + input {
      margin-top: 1rem;
    } */
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    background: var(--green);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    width: 250px;

    a {
      color: var(--blue);
      margin: 0 auto;
      text-decoration: underline;
    }
  }

  h2 + img {
    margin-bottom: 2rem;
  }

  .resultCard {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    width: auto !important;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #fff;
    background: var(--green);
    border: 0;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
    height: 3rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    img {
      margin-left: 10px;
      color: #fff;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 0.25rem;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

export const RadioContainer = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
`;

export const RadioContainerPayment = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; //${(props) => props.profile==='pdv' ? '1fr 1fr 1fr' : '1fr 1fr'};
  gap: 1rem;
`;

export const ValueContainer = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const colors = {
  green: '#33CC95',
  red: '#E52E4D',
};

export const RadioBox = styled.button`
  height: 4rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.25rem;

  background: ${(props) =>
    props.isActive ? colors[props.activeColor] : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s;

  &:hover {
    border-color: ${darken(0.1, '#d7d7d7')};
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: ${(props) =>
      props.isActive ? 'brightness(0) invert(1);' : 'none'};
  }

  span {
    display: inline-block;
    font-size: 1rem;
    max-width: 5.1rem;
    color: ${(props) =>
      props.isActive ? 'var(--shape)' : 'var(--text-title)'};

    span {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${(props) => (props.isActive ? 'var(--shape)' : 'var(--blue)')};
    }
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const IconButton = styled.button`
  width: 45px;
  height: 45px;
  padding: 10px;
  border: none;
  background: transparent;
  vertical-align: middle;
  /* border: 1px solid #d7d7d7;
  border-radius: 0.25 rem; */

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* width: 80%; */
  }
`;

export const IconButtonContainer = styled.span`
  margin-left: -120px;

  button + button {
    margin-left: 12px;
  }
`;
