import { useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import closeImg from '../../../assets/close.svg';
import pixImg from '../../../assets/pix.svg';
import creditCardImg from '../../../assets/creditCard.svg';
import moneyImg from '../../../assets/money.svg';
import allowLocationImg from '../../../assets/allowLocation.png'
import { maskPlate } from '../../../utils/masks';
import MaskedInput from 'react-text-mask';
import { API_URL } from '../../../services/api';
import {
  Container,
  InputsGrid,
  RadioContainerPayment,
  RadioBox,
  ValueContainer,
} from './styles';
import { SearchParkingPlace } from '../../SearchParkingPlace';
import { useEffect } from 'react';
import { ParkingService } from '../../../services/api-zad';
import { toDecimal } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export function ParkingModal({ isOpen, onRequestClose,  }) {
  const placa = sessionStorage.getItem('licensePlate')
  const profile = localStorage.getItem('profile')

  const [allowLocation, setAllowLocation] = useState(false)
  const [addressName, setAddressName] = useState('');
  const [ruleName, setRuleName] = useState('');
  const [rules, setRules] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [amountCad, setAmountCad] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const [loading, setLoading] = useState('');

  const cadValue = Number(process.env.REACT_APP_CAD_VALUE||0)
  const ruleSelected = rules.find(rule => rule.name === ruleName)

  useEffect(() => {
    cleanForm()
    fetchRules()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchRules = async () => {
    try {
      const response = await ParkingService.regionRules();
      setRules(response.data.data);
    } catch(e) {
      console.log(e, ' - ', e.message);
    }
  }

  useEffect(() => {
    if (profile === 'user') setLicensePlate( placa || '')
  }, [profile, placa])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setLatLng, showError)
    }
  }, [isOpen])

  const setLatLng = (position) => {
    setAllowLocation(true)
    setLatitude(position.coords.latitude)
    setLongitude(position.coords.longitude)
  }

  const showError = (error) => {
    setAllowLocation(false)
    console.log('ParkingModal: ', error)
  }

  const handleNewVehicle = async (e) => {
    e.preventDefault();

    if (!addressName) { toast.warn('Informe o local do estacionamento.'); return; }

    if (!latitude) { toast.warn('Latitude: não pode ser nulo.'); return; }

    if (!longitude) { toast.warn('Longitude: não pode ser nulo.'); return; }

    setLoading(true);

    const body = {
      addressName,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      licensePlate,
      ruleName,
      amountCad,
      imei: process.env.REACT_APP_IMEI_DEFAULT,
      firebaseToken: '',
      paymentMethod
    }

    const res = await fetch(`${API_URL}/parkings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    });

    const response = await res.json();

    if (!res.ok) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      cleanForm()
      setTimeout(onRequestClose, 3000);
    }

    setLoading(false);
  };

  const cleanForm = () => {
    setAddressName('')
    setRuleName('')
    setLatitude('')
    setLongitude('')
    setLicensePlate(placa||'')
    setAmountCad('')
    setPaymentMethod('pix')
  }

  const RadioBoxPayment = ({ label, method, image }) => {
    return (
      <RadioBox
        type="button"
        onClick={() => setPaymentMethod(method)}
        isActive={paymentMethod === method}
        activeColor="green"
      >
        <img src={image} alt={label} />
        <span>{label}</span>
      </RadioBox>
    )
  }

  const OptionsForSelect = ({ quantity }) => {
    let options = []

    for (let i = 2; i <= quantity; i++) {
      options.push(<option key={i} value={i}>{i} Cartões</option>)
    }

    return (
      <>{options}</>
    )
  }

  if (!allowLocation) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          cleanForm()
          onRequestClose()
        }}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <ToastContainer />

        <button
          type="button"
          onClick={() => {
            cleanForm()
            onRequestClose()
          }}
          className="react-modal-close"
        >
          <img src={closeImg} alt="Close modal" />
        </button>

        <Container onSubmit={handleNewVehicle}>
          <h2>Estacionar {placa || 'Veículo'}</h2>

          <h3>Permita o uso da sua localização para realizar o estacionamento.</h3>

          <img src={allowLocationImg} alt="Permitir Localização" />
        </Container>
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        cleanForm()
        onRequestClose()
      }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <ToastContainer />

      <button
        type="button"
        onClick={() => {
          cleanForm()
          onRequestClose()
        }}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Close modal" />
      </button>

      <Container onSubmit={handleNewVehicle}>
        <h2>Estacionar {profile === 'user' ? placa || 'Veículo' : 'Veículo'}</h2>

        <MaskedInput
          mask={maskPlate}
          placeholder="Placa"
          guide={false}
          type="text"
          value={licensePlate.toUpperCase()}
          onChange={(event) => setLicensePlate(event.target.value)}
          required
        />

        <SearchParkingPlace 
          setAddress={setAddressName}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          geocode={!latitude}
        />

        <input
          placeholder="Local do estacionamento"
          value={addressName}
          disabled
        />
        <InputsGrid>
          <input
            placeholder="Latitude"
            value={latitude}
            disabled
          />
          <input
            placeholder="Longitude"
            value={longitude}
            disabled
          />
        </InputsGrid>

        <select
          type="text"
          value={ruleName}
          onChange={(event) => {
            setRuleName(event.target.value)
            setAmountCad('')
          }}
          required
        >
          <option value="" disabled defaultValue hidden>
            Regra do local
          </option>
          {
            rules.map(
              (rule, index) => (<option key={index} value={rule.name}>{rule.name}</option>)
            )
          }
        </select>

        <select
          type="text"
          value={amountCad}
          onChange={(event) => setAmountCad(event.target.value)}
          required
        >
          <option value="" disabled defaultValue hidden>
            Quantidade de Cartões
          </option>
          <option key={1} value="1">1 Cartão</option>
          { ruleSelected?.maxCad && <OptionsForSelect quantity={ruleSelected?.maxCad} /> }
        </select>

        {
          profile === 'pdv' && (
            <>
              <ValueContainer>
                <h3>
                  Valor total: <span>R$ {toDecimal(+amountCad * cadValue)}</span>
                </h3>
              </ValueContainer>

              <RadioContainerPayment>
                <RadioBoxPayment label='Dinheiro' method='money' image={moneyImg} />
                <RadioBoxPayment label='Pix' method='pix' image={pixImg} />
                <RadioBoxPayment label='Débito' method='debit_card' image={creditCardImg} />
                <RadioBoxPayment label='Crédito' method='credit_card' image={creditCardImg} />
              </RadioContainerPayment>
            </>
          )
        }

        {loading ? (
          <button type="submit" disabled>
            <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
            Estacionando
          </button>
        ) : (
          <button type="submit">
            <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />
            Estacionar
          </button>
        )}
      </Container>
    </Modal>
  );
}
