import { useState } from 'react'
import { Control, Icon, Input } from 'rbx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressItem, AddressList, Container, ItemIcon } from './styles'
import GeocodeService from '../../services/geocoder'
import { GoogleMapsApi } from '../../services/google-maps-api'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'

export const SearchParkingPlace = ({ setAddress, setLatitude, setLongitude, geocode=true }) => {
  const [searchAddress, setSearchAddress] = useState('')
  const [addresses, setAddresses] = useState([])

  // console.log('SearchParkingPlace - geocode: ', geocode)

  const selectAddress = async (address) => {
    if (geocode) {
      const response = await GeocodeService.fromAddress(address)
      const { lat, lng } = response.results[0].geometry.location
      setLatitude(lat)
      setLongitude(lng)
    }
    // const { formatted_address } = response.results[0]
    setAddress(address)
    setAddresses([])
    setSearchAddress('')
  }

  const fetchAddressesByGoogleMaps = async (value) => {
    if (value.length >= 3) {
      const response = await GoogleMapsApi.fromAddress(value)
      setAddresses(response.data.data)
    } else {
      if (addresses.length) setAddresses([])
    }
  }

  return (
    <Container>
      <Control iconLeft>
        <Input 
          id='search'
          placeholder="Pesquise o local do estacionamento"
          autoComplete='off'
          value={searchAddress}
          onChange={(event) => {
            fetchAddressesByGoogleMaps(event.target.value)
            setSearchAddress(event.target.value)
          }}
        />
        <Icon align="left">
          <FontAwesomeIcon icon={faMapPin} />
        </Icon>
        <AddressList addresses={searchAddress}>
          {
            addresses.length ? (
              addresses.map((address) => (
                <AddressItem onClick={index => selectAddress(address)}>
                  <ItemIcon /> { address }
                </AddressItem>
              ))
            ) : (
              <AddressItem>
                Nenhum endereço encontrado.
              </AddressItem>
            )
          }
        </AddressList>
      </Control>
    </Container>
  )
}