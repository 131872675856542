import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.form`
  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: var(--blue);
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  select {
    margin-top: 1rem;

    /* -webkit-appearance: none;
    -moz-appearance: none; */

    /* &:invalid {
      color: var(--text-body);
    } */
  }

  input,
  select {
    width: 100%;
    padding: 0 1.5rem;
    height: 3.1rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: var(--input-background);

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input,
    & + select,
    & + div {
      margin-top: 0.8rem;
    }
  }

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;
    background: var(--blue);
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    cursor: pointer;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  input + input {
    margin-top: 0rem;
  }
`;

export const IconButton = styled.button`
  position: absolute;
  top: -17px;
  padding: 0 15px;
  border: none;
  background: var(--input-background);
  height: 100%;
  cursor: pointer;
  vertical-align: middle;

  height: 3.5rem;
  border-radius: 0.25rem;
  /* border: 1px solid #d7d7d7; */

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    max-width: revert;
  }
`;

export const IconButtonContainer = styled.span`
  position: relative;
  margin-left: -60px;
`;

export const RadioContainerPayment = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
`;

export const ValueContainer = styled.div`
  margin: 1rem 0;

  h3 {
    color: var(--text-title);

    span {
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--blue);
    }
  }
`;

const colors = {
  green: '#33CC95',
  red: '#E52E4D',
};

export const RadioBox = styled.button`
  height: 3rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.25rem;

  background: ${(props) =>
    props.isActive ? colors[props.activeColor] : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s;

  &:hover {
    border-color: ${darken(0.1, '#d7d7d7')};
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    filter: ${(props) =>
      props.isActive ? 'brightness(0) invert(1);' : 'none'};
  }

  span {
    display: inline-block;
    font-size: 1rem;
    max-width: 5.1rem;
    color: ${(props) =>
      props.isActive ? 'var(--shape)' : 'var(--text-title)'};

    span {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${(props) => (props.isActive ? 'var(--shape)' : 'var(--blue)')};
    }
  }
`;
