export const Validator = {
  birthday: (birthday, profile) => {
    const birthdayDate = new Date(birthday)

    if (new Date() - birthdayDate <= 0) return [false, 'Data inválida.']

    if (new Date().getFullYear() - birthdayDate.getFullYear() >= 125 && profile !== 'pdv') return [false, 'Data de nascimento inválida.']

    if (new Date().getFullYear() - birthdayDate.getFullYear() <= 18 && profile !== 'pdv') return [false, 'Usuário menor de idade.']

    return [true, 'Data válida']
  }
}