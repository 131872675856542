import { FooterHome } from "../../components/FooterHome";
import { HeaderHome } from "../../components/HeaderHome";
import { Container, Content, Cover } from "../../styles/styles";
import { RedirectTo } from "../../utils/redirectTo";
import WhatsappButton from "../HomePage/components/WhatsappButton";

export default function LayoutHomePage({ children }) {
  const city = process.env.REACT_APP_CITY||''
  return (
    <>
      <Container>
        <HeaderHome redirectTo={RedirectTo.home} />
        <Content id="topo">
          <Cover>
            <h1>Zona Azul Digital { city }</h1>
            <p>A solução ideal para o estacionamento rotativo da sua cidade</p>
          </Cover>
          {children}
        </Content>
      </Container>
      <FooterHome />
      <WhatsappButton />
    </>
  );
}
