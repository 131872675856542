import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  background: var(--container);

  .parking {
    justify-content: flex-end;
  }

  .second {
    background: #a7c957;
  }

  .third {
    background: var(--blue);
  }

  header,
  p {
    margin-top: 0 !important;
  }

  button.buy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #fff;
    background: var(--green);
    border: 0;
    padding: 0 1.5rem;
    border-radius: 0.25rem;
    height: 3rem;
    width: 10rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }

    img {
      margin-left: 10px;
    }
  }

  strong img {
    margin: 0 10px;
  }

  strong + button {
    background: var(--blue);
  }

  .dropdown {
    background: var(--shape);
    padding: 0;
    display: inline-flex;

    .button {
      padding: 0px 10px
    }

    .dropdown-trigger,
    .dropdown-menu,
    .dropdown-content {
      background: var(--shape);
      padding: 0;
    }

    .dropdown-menu {
      margin-top: 0;
      right: 0;
      min-width: 12rem;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      z-index: 20;
    }

    .dropdown-content {
      border-radius: 4px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }

    .dropdown-item {
      text-align: left;
      color: #4a4a4a;
      display: block;
      font-size: 1rem;
      line-height: 1.5;
      padding: 0.5rem 1rem;
      position: relative;
    }
  }
`;

export const IconButton = styled.button`
  height: 34px !important;
  width: 50px !important;
  padding: 5px !important;
  border: none !important;
  background: transparent !important;
`;

export const Flex = styled.span`
  display: flex;
  margin-bottom: 1rem;
`;
