import { HeaderHome } from "../../components/HeaderHome";
import { Container, Content } from "./styles";

export default function ServiceTermsPage() {
  const city = process.env.REACT_APP_CITY||''
  const state = process.env.REACT_APP_STATE||''
  const local = city ? city + ' - ' + state : ''

  const serviceTermsCompany = process.env.REACT_APP_SERVICE_TERMS_COMPANY
  const serviceTermsCNPJ = process.env.REACT_APP_SERVICE_TERMS_CNPJ

  return (
    <Container>
      <HeaderHome />
      <Content>
        <h1>Termos de Serviço</h1>

        <ol>
          <li>
            <b>
              Aceitação dos Termos e Condições Gerais de Uso do Aplicativo Zona
              Azul Digital (ZAD) { city }
            </b>
            <ol>
              <li>
                Os termos e condições gerais de uso, abaixo especificados e
                denominados como “Termos e Condições” ou “Contrato”, aplicam-se
                ao uso dos serviços oferecidos pela empresa {serviceTermsCompany},
                empresa devidamente inscrita no CNPJ sob o
                nº {serviceTermsCNPJ}, doravante nominada como "Concessionária",
                por meio de seu aplicativo para smartphones denominado Zona Azul
                Digital (ZAD) { city } ou “App”.
              </li>
              <li>
                Qualquer pessoa que pretenda utilizar os serviços do "App"
                deverá aceitar estes "Termos e Condições", na forma abaixo
                descrita. A partir do aceite a estes "Termos e Condições", o
                interessado passa a ser usuário do "App", sendo, então,
                denominado “Usuário”.
              </li>
              <li>
                Para se tornar "Usuário", o interessado deverá ler,
                certificar-se de haver entendido e aceitar integralmente este
                "Contrato", clicando no botão "Li e Concordo". Esta ação o
                vinculará automaticamente às regras aqui contidas.
              </li>
              <li>
                As informações presentes no "App" poderão ser modificadas ou
                extintas a qualquer momento, sem que haja a necessidade de
                notificação prévia ao “Usuário”. O “Usuário” poderá a qualquer
                momento revisar a versão mais recente dos termos e condições de
                uso, acessando a opção "Termos e Condições" localizado na aba
                “Informações” do "App". O “Usuário” será responsável por
                verificar periodicamente se ocorreram quaisquer mudanças neste
                termo. Se o "Usuário" continuar usando o "App" após mudanças nos
                "Termos e Condições", estará indicando automaticamente o seu
                aceite quanto às novas condições.
              </li>
              <li>
                A ACEITAÇÃO DESTES TERMOS E CONDIÇÕES É ABSOLUTAMENTE
                INDISPENSÁVEL À UTILIZAÇÃO DO "App".
              </li>
            </ol>
          </li>
          <li>
            <b>Definições adicionais</b>
            <ol>
              <li>
                {serviceTermsCompany} - entidade privada
                responsável pela gestão e fiscalização das vagas de
                estacionamento rotativo na cidade de { city }.
              </li>
              <li>
                CAD (Cartão Azul Digital) - unidade monetária emitida pela
                "Concessionária".
              </li>
              <li>
                AUTENTICAÇÃO - identificação única fornecida pela
                "Concessionária" após cada transação de Aquisição e/ou Ativação
                de CADs.
              </li>
              <li>
                AQUISIÇÃO - transação monetária realizada pelo "Usuário" no
                "App", para compra e Autenticação de 1 ou mais CADs.
              </li>
              <li>
                ATIVAÇÃO - transação que ocorre entre a "Concessionária" e o
                Usuário, mediante solicitação do "Usuário" no "App", autorizando
                o estacionamento e dando início ao tempo de validade do CAD,
                garantindo ao "Usuário" o direito de estacionar o veículo em uma
                das vagas demarcadas como estacionamento rotativo "ZONA AZUL".
              </li>
              <li>
                REGRA DE ESTACIONAMENTO - período de estacionamento válido para
                cada CAD, de acordo com a regra de estacionamento definida pela
                Concessionária nos diferentes locais da cidade. Cada unidade de
                CAD pode ter a validade de 60 ou 120 minutos.
              </li>
            </ol>
          </li>
          <li>
            <b>Descrição do Objeto</b>
            <ol>
              <li>
                O "App" possibilita ao "Usuário" a Aquisição e Ativação de CADs
                para uso das vagas de estacionamento rotativo de veículos "ZONA
                AZUL" no município.
              </li>
              <li>
                O uso do "App" deverá ser feito pelo "Usuário" através de seu
                smartphone pessoal, sempre mediante acesso à rede mundial de
                computadores (Internet).
              </li>
              <li>
                O "Usuário" poderá, alternativamente, fazer a Ativação de CADs
                através de contato telefônico (quando na impossibilidade de
                acesso à internet) disponibilizado pela "Concessionária" , porém
                somente quando houver pelo menos 1 CAD adquirido anteriormente.
              </li>
            </ol>
          </li>
          <li>
            <b>Do Cadastro e Exclusão de Usuário e Cartões de Crédito</b>
            <ol>
              <li>
                A primeira etapa do cadastro será preencher os dados pessoais, o
                "Usuário" poderá fornecer dados como Pessoa Física ou Jurídica.
                <ol>
                  <li>
                    Em caso de Pessoa Física, deverá informar Nome, CPF, Data de
                    nascimento, Telefone, E-mail e criar uma Senha para o “App”.
                  </li>
                  <li>
                    Em caso de Pessoa Jurídica, deverá informar Nome/Razão
                    Social, CNPJ, Telefone, E-mail e criar uma Senha para o
                    “App”.
                  </li>
                  <li>
                    A Pessoa Jurídica deverá cadastrar-se por meio de seu
                    representante legal ou procurador devidamente constituído.
                  </li>
                </ol>
              </li>
              <li>
                O envio de mensagens para o e-mail informado pelo "Usuário" fica
                previamente definido como uma forma válida de comunicação entre
                as partes.
              </li>
              <li>
                A senha deverá conter pelo menos 6 caracteres e é de uso pessoal
                e intransferível.
                <ol>
                  <li>
                    Caso o "Usuário" desconfie que sua senha foi roubada e/ou
                    está sendo utilizado por terceiros, deve utilizar
                    imediatamente a função "Definir nova senha" na aba “Editar
                    Usuário”.
                  </li>
                </ol>
              </li>
              <li>
                O "Usuário" poderá alterar seus dados pessoais a qualquer
                momento, com exceção do CPF ou CNPJ informados no momento do
                cadastro.
                <ol>
                  <li>
                    Caso seja necessário alterar as informações de CPF e CNPJ, o
                    "Usuário" deverá entrar em contato com o suporte do
                    aplicativo, através do e-mail { process.env.REACT_APP_SUPPORT_EMAIL }.
                  </li>
                  <li>
                    É possível cadastrar novos cartões de crédito através da
                    função "Cadastrar novo cartão" disponível na aba “Minhas
                    Informações/Meus Cartões” do "App". Todas as informações do
                    cartão de crédito cadastrado, com exceção do código de
                    segurança, permanecerão salvas somente no celular.
                  </li>
                </ol>
              </li>
              <li>
                O “Usuário” poderá excluir seus dados pessoais da base da
                Concessionária a qualquer momento. Serão excluídos os seguintes
                dados:
                <ol>
                  <li>
                    Em caso de Pessoa Física, será Nome, CPF, Data de
                    nascimento, Telefone, E-mail e o(s) CAD(s) existente(s) na
                    carteira do Usuário.
                  </li>
                  <li>
                    Em caso de Pessoa Jurídica, será Nome/Razão Social, CNPJ,
                    Telefone, E-mail e o(s) CAD(s) existente(s) na carteira do
                    Usuário.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Da Aquisição de CADs</b>
            <ol>
              <li>
                A compra de CADs poderá ser feita mediante cadastramento de um
                cartão de crédito válido e utilizando o botão “Comprar” presente
                na tela principal do "App".
              </li>
              <li>O "Usuário" poderá comprar quantos CADs desejar.</li>
              <li>
                O "Usuário" poderá adquirir CADs antecipadamente ou fazê-lo no
                momento que desejar estacionar o veículo. Neste caso, ocorrerão
                simultaneamente as etapas de Aquisição e Ativação de CAD.
              </li>
            </ol>
          </li>
          <li>
            <b>Da Ativação de CADs</b>
            <ol>
              <li>
                Para habilitar o estacionamento em vagas demarcadas como "ZONA
                AZUL", o "Usuário" precisará fazer a Ativação do CAD através do
                "App".
              </li>
              <li>
                Para fazer uma Ativação é necessário possuir saldo de CADs.
              </li>
              <li>
                O "Usuário" poderá ativar 1 ou, no máximo, 2 CADs por veículo e
                local.
              </li>
              <li>
                O "Usuário" não poderá ativar mais de 3 CADs por smartphone
                simultaneamente.
              </li>
              <li>
                O "Usuário" deverá escolher para qual veículo deseja fazer a
                ativação, usar a função "Estacionar" e confirmar a REGRA DE
                ESTACIONAMENTO vigente para o local e horário desejados.
              </li>
              <li>
                Após o "Usuário" confirmar que deseja realizar a ativação, o
                "App" informará se a transação de Ativação foi bem sucedida ou
                não.
              </li>
              <li>
                Em caso de sucesso, o "App" exibirá uma mensagem confirmando a
                Ativação. Para acessar os detalhes e o código de autenticação da
                "Concessionária" o “Usuário” deverá acessar a aba
                “Histórico/Compras” ou acessar o “Histórico/Utilizações” após o
                término do estacionamento.
              </li>
              <li>
                O “Usuário” pode ativar ou desativar a funcionalidade de
                notificação de alerta sobre o estacionamento. É possível
                programar o “App” para notificar faltando 20 minutos, 15 minutos
                ou no momento do término do estacionamento, bem como programar
                um alerta com tempo configurável dentro do intervalo
                preestabelecido de tempo do estacionamento.
              </li>
              <li>
                Caso o "Usuário" tenha optado inicialmente pelo uso de apenas 1
                CAD e deseje estender o tempo de estacionamento, bastará
                utilizar a função "Renovação Automática de Estacionamento” e
                aguardar a confirmação com sucesso.
              </li>
              <li>
                Ao expirar o tempo limite, ou seja, no momento do término do
                estacionamento no “App”, será necessária a remoção imediata do
                veículo do local estacionado. Do contrário, este veículo estará
                irregular.
              </li>
            </ol>
          </li>
          <li>
            <b>Condições Gerais do Serviço</b>
            <ol>
              <li>
                O "Usuário" poderá utilizar o "Aplicativo" 24 (vinte e quatro)
                horas por dia, 7 (sete) dias por semana, salvo eventuais
                interrupções que se façam necessárias por ocasião de manutenção
                do sistema ou base de dados, falhas em decorrência da falta de
                fornecimento de energia elétrica e/ou telecomunicação, casos
                fortuitos e/ou força maior, ou ainda, ações ou omissões de
                terceiros.
              </li>
              <li>
                O "Usuário" declara ser o único responsável pela aquisição,
                contratação, instalação e manutenção de quaisquer equipamentos
                e/ou serviços necessários para utilização da Internet (telefone
                celular, computador, linha telefônica, provedor de acesso,
                energia elétrica e outros).
              </li>
              <li>
                O "Usuário" declara estar ciente que eventuais problemas na
                contratação, falta de disponibilidade e/ou cobrança dos
                serviços/equipamentos de telecomunicação e/ou conexão à Internet
                deverão ser resolvidos diretamente com as empresas fornecedoras
                dos respectivos serviços/equipamentos, sem qualquer
                responsabilidade ou interferência da "Concessionária".
              </li>
            </ol>
          </li>
          <li>
            <b>Das Obrigações do Usuário</b>
            <ol>
              <li>
                Observar e seguir rigorosamente as regras de trânsito e de
                estacionamento vigentes para a área e horário em que o veículo
                será estacionado.
              </li>
              <li>
                Informar ao "App", sempre que solicitado, dados verdadeiros e
                corretos, incluindo, mas não se limitando aos seus dados
                pessoais, de veículos, de cartões de crédito, bem como sobre o
                tempo e regras para estacionamento.
              </li>
              <li>
                Manter seus dados pessoais atualizados no "App", editando-os
                sempre que sofrerem alguma alteração.
              </li>
              <li>
                Utilizar as funcionalidades do "App" ou demais serviços
                oferecidos pela “Concessionária” com total boa-fé e probidade, e
                nunca para fins ilegais, fraudulentos ou em desacordo com a
                legislação brasileira.
              </li>
              <li>
                Guardar absoluto sigilo sobre sua senha de acesso, realizando a
                respectiva troca sempre que julgar necessário.
              </li>
              <li>
                Notificar imediatamente a “Concessionária”, através das funções
                "Fale Conosco" ou "Reportar problema" no "App", caso identifique
                qualquer falha, mal funcionamento ou mesmo informação incorreta
                que possa vir a causar quaisquer danos ou prejuízos à
                “Concessionária” ou demais Usuários.
              </li>
              <li>
                Não tentar, de nenhuma forma, obter acesso não autorizado aos
                sistemas da “Concessionária” e/ou de suas afiliadas, violando
                sistemas de segurança ou utilizando qualquer método de invasão
                e/ou outros considerados de má-fé ou ilegais.
              </li>
              <li>
                Sem prejuízo das obrigações previstas neste instrumento, o
                "Usuário" declara-se ciente que poderá responder civil, criminal
                e administrativamente pelos danos ou prejuízos que seus atos
                possam vir a causar decorrente da utilização deste “App”.
              </li>
            </ol>
          </li>
          <li>
            <b>Das Obrigações da “Concessionária”</b>
            <ol>
              <li>
                A “Concessionária” compromete-se a destinar seus melhores
                esforços para aprimorar a qualidade e assegurar a
                disponibilidade dos serviços prestados através do "App".
              </li>
              <li>
                Guardar absoluto sigilo sobre os dados pessoais do "Usuário" e
                atender aos requisitos da Lei 13.709/2018 (Lei de proteção de
                dados), podendo, entretanto, divulgá-las mediante as seguintes
                ocasiões:
                <ol>
                  <li>
                    Por determinação judicial ou administrativa, e por
                    determinação de autoridade pública, nos termos da lei.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Das Responsabilidades da “Concessionária”</b>
            <ol>
              <li>
                A “Concessionária” não se responsabiliza, em nenhuma hipótese,
                por quaisquer despesas, prejuízos e/ou danos causados ao
                "Usuário" ou a terceiros, ocasionados:
                <ol>
                  <li>
                    pela perda da senha do "Usuário" ou utilização desta por
                    outrem.
                  </li>
                  <li>
                    por erros de digitação, incluindo, mas não se limitando à
                    placa do veículo cadastrado no "App".
                  </li>
                  <li>
                    pela inobservância, desatenção ou entendimento equivocado,
                    por parte do "Usuário", quanto às regras de trânsito e/ou de
                    estacionamento do local onde o veículo será estacionado.
                  </li>
                  <li>
                    pela utilização incorreta do "Aplicativo", incluindo, mas
                    não se limitando a eventuais erros durante o processo de
                    Ativação dos CADs adquiridos ou ainda quando o veículo não
                    for retirado da vaga após ter excedido o tempo máximo
                    permitido.
                  </li>
                  <li>
                    por quaisquer penalidades de trânsito recebidas pelo
                    "Usuário", incluindo, mas não se limitando a multas
                    relacionadas a estacionamento irregular.
                  </li>
                  <li>
                    pela aquisição, contratação ou negociação de qualquer
                    natureza envolvendo o "Usuário" e titulares de
                    empresas/sites terceiras, mesmo quando decorrer de
                    publicidade anunciada dentro do "App".
                  </li>
                  <li>
                    pelo dano, prejuízo ou perda de quaisquer equipamentos do
                    "Usuário", causados por falha no sistema, no servidor ou na
                    internet, ou em decorrência da transferência de dados,
                    arquivos, imagens e textos para o equipamento do "Usuário".
                  </li>
                  <li>
                    pelo mal funcionamento de equipamentos ou serviços de
                    telecomunicação, bem como de softwares de terceiros,
                    utilizados pelo "Usuário".
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Exclusão do Aplicativo e Estorno de CADs</b>
            <ol>
              <li>
                a exclusão do app não corresponde ao exclusão dos dados do
                Usuário (citada no item 4 acima).
              </li>
              <li>
                caso o "Usuário" decida deixar de utilizar o "App" ou mesmo
                excluí-lo de seu smartphone, os CADs anteriormente adquiridos
                permanecerão vinculados à conta em questão.
              </li>
              <li>
                O estorno dos valores dos CADs adquiridos SOMENTE ocorrerá na
                hipótese de, comprovadas, falhas na comunicação com o sistema da
                "Concessionária".
              </li>
            </ol>
          </li>
          <li>
            <b>Do Foro</b>
            <ol>
              <li>
                As partes elegem o Foro Central da Comarca { city ? 'de '+local : 'Municipal' } para 
                dirimir eventuais dúvidas ou controvérsias decorrentes do
                presente Contrato, excluindo-se qualquer outro, por mais
                privilegiado que seja.
              </li>
            </ol>
          </li>
        </ol>
      </Content>
    </Container>
  );
}
